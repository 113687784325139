/**
 * GlobalPopover
 */

import React, { useMemo, useState } from 'react';
import { useSelector } from '@xstate/react';

import InfoBox from 'components/InfoBox';
import Placeholder from 'components/Placeholder';
import Popover from 'components/Popover';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import { useGlobalMachinesContext } from 'contexts';
import {
	selectError,
	selectHeading,
	selectIsLoading,
	selectIsOpen,
	selectRoute,
	selectTargetIsLoaded,
} from 'state-machines/global-popover.machine';
import { useI18n } from 'utils/i18n';

import { GlobalPopoverContext } from './GlobalPopoverContext';

function GlobalPopoverLoader() {
	return (
		<Skeleton>
			<div className="space-y-3">
				<SkeletonItem height="3rem" className="mb-6" />
				<SkeletonItem height="1.5rem" />
				<SkeletonItem height="1.5rem" />
				<SkeletonItem height="1.5rem" className="w-1/2" />
			</div>
		</Skeleton>
	);
}
GlobalPopoverLoader.displayName = 'GlobalPopoverLoader';

/** A global popover that can be used from anywhere. */
export default function GlobalPopover() {
	const { t } = useI18n();
	const { globalPopoverService } = useGlobalMachinesContext();
	const { send } = globalPopoverService;
	const isOpen = useSelector(globalPopoverService, selectIsOpen);
	const isLoading = useSelector(globalPopoverService, selectIsLoading);
	const targetIsLoaded = useSelector(
		globalPopoverService,
		selectTargetIsLoaded,
	);
	const heading = useSelector(globalPopoverService, selectHeading);
	const error = useSelector(globalPopoverService, selectError);
	const route = useSelector(globalPopoverService, selectRoute);
	const [topMessage, setTopMessage] = useState<React.ReactNode>();
	const contextValue = useMemo(
		() => ({ setTopMessage, topMessage }),
		[topMessage],
	);

	return (
		<GlobalPopoverContext.Provider value={contextValue}>
			<Popover
				isOpen={isOpen}
				id="global-popover"
				// In practice the heading will probably always have a value, even if
				// the type is technically nullable.
				title={heading || ''}
				afterHeader={topMessage}
				onClose={() => {
					send('CLOSE');
				}}
				contentClassName="flex flex-col"
			>
				{isLoading && <GlobalPopoverLoader />}
				{targetIsLoaded && route && (
					<div className="grow [&>*:first-child]:mt-0">
						<Placeholder name="jula-main" rendering={route} />
					</div>
				)}
				{error && (
					<InfoBox
						variant="error"
						icon="error"
						message={t('general_critical_error_text')}
					/>
				)}
			</Popover>
		</GlobalPopoverContext.Provider>
	);
}
GlobalPopover.displayName = 'GlobalPopover';
