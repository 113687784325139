import type {
	KeyboardEvent as ReactKeyboardEvent,
	MouseEvent as ReactMouseEvent,
} from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { parseUrl } from 'query-string';

import { publicRuntimeConfig } from 'config';
import type {
	CustomLayoutServiceData,
	LayoutServicePlaceholder,
} from 'lib/page-props';
import type {
	BusinessLogicError,
	CartError,
	FieldValidationError,
	JulaValidationError,
} from 'models/api';
import type { BlockImageRatios, DigizuiteAsset } from 'models/asset';
import type {
	Falsy,
	GlobalCustomEvent,
	Newable,
	NonArrayObject,
	NonZeroFalsy,
	PlainObject,
} from 'types';

/**
 * Empty objects that referentially stay the same.
 *
 * Use for things like fallback return values where the value may affect
 * component rendering. Creating new objects on every change can trigger
 * unnecessary renders.
 *
 * @example
 *
 * const allThings = {
 *   stuff: [...],
 *   moreStuff: [...],
 * };
 * const getThings = (type: string) => allThings[type] || empty.array;
 */
export const empty = {
	array: [],
	object: {},
	func: () => {},
};

// One-stop type checking shop. Use doc blocks for checks that aren't obviously
// self-explanatory.
export const is = {
	array: Array.isArray,
	arrayWithLength: <T>(val: unknown): val is T[] =>
		Array.isArray(val) && val.length > 0,
	null: (val: unknown): val is null => val === null,
	undefined: (val: unknown): val is undefined => val === undefined,
	defined: <T>(val: T | undefined | null): val is T =>
		val !== undefined && val !== null,

	/** Check if a value is undefined or null. */
	nullish: (val: unknown): val is null | undefined => val == null,
	// eslint-disable-next-line @typescript-eslint/ban-types
	func: (val: unknown): val is Function => typeof val === 'function',

	/** Check if a value is a number or a numeric string. */
	numeric: (val: unknown): val is number | string =>
		is.number(val) || Boolean(is.string(val) && val && is.number(Number(val))),

	/** Check if a value is a finite, non-NaN number. */
	number: (val: unknown): val is number =>
		Number.isFinite(val) && !Number.isNaN(val),
	positiveNumber: (val: unknown): val is number => is.number(val) && val > 0,
	integer: Number.isInteger,
	string: (val: unknown): val is string => typeof val === 'string',
	bool: (val: unknown): val is boolean => val === true || val === false,
	// eslint-disable-next-line unicorn/prefer-native-coercion-functions
	truthy: <T>(val: T | Falsy): val is T => Boolean(val),
	falsy: (val: unknown): val is Falsy => !val,
	nonZeroFalsy: (val: unknown): val is NonZeroFalsy =>
		!val && val !== 0 && val !== 0n,

	/** Check if a value is a non-null, non-array object. */
	object: (val: unknown): val is NonArrayObject =>
		Boolean(val && typeof val === 'object' && !is.array(val)),

	/**
	 * Check if a value is a non-null, non-array object that has
	 * at least one property.
	 */
	objectWithKeys: (val: unknown): val is NonArrayObject =>
		is.object(val) && Object.keys(val).length > 0,

	/** Check if a value is a plain object, i.e. without a constructor. */
	plainObject: (val: unknown): val is PlainObject =>
		is.object(val) &&
		Object.prototype.toString.call(val) === '[object Object]' &&
		(val.constructor === Object || val.constructor === undefined),

	/**
	 * Check if a value is a promise.
	 *
	 * Doesn't actually ensure that the value is a promise instance (for example,
	 * `is.promise({ then() {} }))` will pass), but the language itself apparently
	 * identifies promises this way and the example object can be awaited.
	 * https://stackoverflow.com/a/27746324
	 */
	promise: <T>(val: unknown): val is Promise<T> =>
		is.object(val) && 'then' in val && is.func(val.then),

	/**
	 * Check if a value can be rendered visibly by React.
	 *
	 * Not the same as truthy checking since this handles the number 0 and
	 * returns false for non-primitives like objects.
	 */
	renderable: <T extends string | number>(
		val: T | boolean | null | undefined,
	): val is T => Boolean(is.string(val) && val) || is.number(val),

	/** Check if a value is an instance of the specified constructor. */
	instance: <T>(val: unknown, constructorFunc: Newable<T>): val is T =>
		val instanceof constructorFunc,

	/** Check if a value is a `Node` object. */
	node: (val: unknown): val is Node => val instanceof Node,

	/** Check if a value is an `HTMLElement` object. */
	element: (val: unknown): val is HTMLElement => val instanceof HTMLElement,

	/** Check if a value is one of the specified options. */
	oneOf: <T extends U, U>(value: U, ...options: [T, T, ...T[]]): value is T =>
		options.includes(value as T),

	/** Check if a value is one of the specified object's keys. */
	keyOf: <T extends object>(
		obj: T,
		key: string | number | symbol,
	): key is keyof T => key in obj,
};

/**
 * Check if a keyboard modifier key for opening a link in a new tab is being
 * held down for an event.
 */
export function isHoldingNewTabKey(
	e: MouseEvent | KeyboardEvent | ReactMouseEvent | ReactKeyboardEvent,
): boolean {
	// Ctrl for Windows and Linux, meta (= ⌘ key) for mac.
	return e.ctrlKey || e.metaKey;
}

/**
 * Ensure a value is an array. Null or undefined results in an empty array.
 *
 * @example
 *
 * asArray(3);
 * // => [3]
 *
 * asArray([1, 2, 3]);
 * // => [1, 2, 3]
 *
 * asArray(undefined);
 * // => []
 */
export function asArray<T>(val: T | T[] | undefined | null): T[] {
	return is.nullish(val) ? empty.array : is.array(val) ? val : [val];
}

/**
 * Create a range of numbers up to, but not including, the end value.
 *
 * @example
 *
 * // End value only, starts from 0:
 * range(3);
 * // => [0, 1, 2]
 *
 * // Start and end values:
 * range(1, 4);
 * // => [1, 2, 3]
 *
 * // Step value to increment by:
 * range(2, 11, 2);
 * // => [2, 4, 6, 8, 10]
 */
export function range(end: number): number[];
export function range(start: number, end: number): number[];
export function range(start: number, end: number, step: number): number[];
export function range(
	startOrEnd: number,
	end?: number,
	step: number = 1,
): number[] {
	const rangeStart = is.number(end) ? startOrEnd : 0;
	const rangeEnd = end || startOrEnd || 0;
	const nums: number[] = [];
	for (let i = rangeStart; i < rangeEnd; i += step || 1) {
		nums.push(i);
	}
	return nums;
}

/**
 * Send the user to a new URL and pass along data.
 *
 * Constructs a hidden form and submits it. Can't use window.location for POST
 * requests.
 *
 * @param url - URL to redirect to.
 * @param method - HTTP method, GET or POST.
 * @param data - Data to pass along.
 * @param moveQuery - If any query string parameters should be moved
 *   to the POST body when using POST.
 */
export function browserRedirect(
	url: string,
	// Change to 'GET' | 'POST'? Will require casting where used.
	method: string = 'GET',
	data?: object,
	moveQuery = false,
) {
	if (!is.oneOf(method.toUpperCase(), 'GET', 'POST')) {
		throw new TypeError('browserRedirect: method must be GET or POST');
	}

	// Keep it simple when possible
	if (method === 'GET' && !data) {
		globalThis.location.href = url;
		return;
	}

	const form = document.createElement('form');
	form.action = url;
	form.method = method;

	let body = data || {};
	if (moveQuery || method === 'GET') {
		const urlParts = parseUrl(url, { sort: false });
		form.action = urlParts.url;
		body = { ...urlParts.query, ...body };
	}

	// Add a hidden input for each value
	Object.entries(body)
		.filter(([, val]) => !is.nullish(val))
		.map(([key, val]) => {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = key;
			input.value = val as string;
			return input;
		})
		.forEach((input) => {
			form.append(input);
		});

	// Insert and submit the form
	document.body.append(form);
	form.submit();
}

/**
 * Singles out the provided ratio from the images array.
 */
export function getAsset(
	ratio: BlockImageRatios,
	assets: Field<DigizuiteAsset[]> | DigizuiteAsset[] | undefined,
): DigizuiteAsset | undefined {
	if (!assets) {
		return undefined;
	}
	const assetList = is.array(assets) ? assets : assets.value;
	return assetList?.find((img) => img && img.formatName === ratio);
}

/** Detrmins if a certain error is included in errorlist */
export const isThereError = (
	_error: string,
	errorList?: CartError[],
	_key?: string,
): boolean => {
	const errorFound = errorList?.find((error: CartError) => {
		if (_key) {
			return error.type === _error && error.key === _key;
		}
		return error.type === _error;
	});
	return Boolean(errorFound);
};

/** Finds the tilte of error from errorlist */
export const findTheErrorTitle = (
	_error: string,
	errorList?: CartError[],
): string | undefined => {
	const error = errorList?.find((err) => err.type === _error);
	return error?.description;
};

export const formatValidationError = ({ errors }: JulaValidationError) => {
	if (!errors || errors.length === 0) {
		return undefined;
	}
	return {
		businessLogicErrors: errors.filter(
			(error): error is BusinessLogicError => !('fieldName' in error),
		),
		fieldValidationErrors: errors
			.filter((error): error is FieldValidationError => 'fieldName' in error)
			.reduce((fields, currentField) => {
				const field = fields[currentField.fieldName];
				if (field) {
					field.push(currentField.text);
				} else {
					fields[currentField.fieldName] = [currentField.text];
				}
				return fields;
			}, {}),
	};
};

/**
 * Get a promise that's resolved after specified time.
 */
export function sleep<T>(duration: number, resolveWith?: T): Promise<T> {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(resolveWith as T);
		}, duration);
	});
}

/**
 * Run a callback if a promise takes longer than specified to resolve.
 *
 * @example
 *
 * // Only show a spinner if the action takes longer than 150 ms.
 * const actionResult = await raceTimeout(possiblySlowAction(), 150, () => {
 *   showLoadingSpinner();
 * });
 */
export async function raceTimeout<T>(
	action: Promise<T>,
	timeout: number,
	timeoutCallback: () => void,
): Promise<T> {
	const sleepResult = '__waiting__';
	const result = await Promise.race([action, sleep(timeout, sleepResult)]);
	if (result === sleepResult) {
		timeoutCallback();
	}
	return action;
}

/**
 * Escape RegExp special characters (grabbed from lodash).
 */
export function escapeRegExp(str: string): string {
	const reRegExpChar = /[$()*+.?[\\\]^{|}]/g;
	const reHasRegExpChar = new RegExp(reRegExpChar.source);
	return str && reHasRegExpChar.test(str)
		? str.replaceAll(reRegExpChar, String.raw`\$&`)
		: str;
}

enum Groups {
	C0002 = 'analytics',
	C0004 = 'marketing',
	C0005 = 'social',
}
type GroupNames = `${Groups}`;
interface ConsentData {
	awaitingReconsent: boolean | undefined;
	consentId: string | undefined;
	datestamp: string | undefined;
	geolocation: string | undefined;
	groups: GroupNames[];
	hosts: string | undefined;
	interactionCount: number | undefined;
	isIABGlobal: boolean | undefined;
	landingPath: string | undefined;
	version: string | undefined;
}

const getConsentedGroups = (groupsString: string | undefined) => {
	const groups: ConsentData['groups'] = [];

	if (!groupsString) return groups;
	for (const group of groupsString.split(',')) {
		const [id, count] = group.split(':') as [keyof typeof Groups, string];
		const hasConsented = !!Number.parseInt(count, 10);
		const consentGroup = Groups[id];
		if (hasConsented && consentGroup) {
			groups.push(consentGroup);
		}
	}
	return groups;
};

export function parseConsentString(
	consentString: string | undefined,
): ConsentData | undefined {
	if (!consentString) {
		return undefined;
	}

	const data: Record<string, string | undefined> = {};
	const params = consentString.split('&');

	for (const param of params) {
		const [key, value] = param.split('=');
		if (key && value) {
			data[key] = value;
		}
	}

	return {
		isIABGlobal: data?.isIABGlobal === 'true',
		datestamp: data?.datestamp
			? decodeURIComponent(data?.datestamp)
			: undefined,
		version: data?.version,
		hosts: data?.hosts,
		consentId: data?.consentId,
		interactionCount: data?.interactionCount
			? Number.parseInt(data?.interactionCount, 10)
			: undefined,
		landingPath: data?.landingPath,
		groups: [...getConsentedGroups(data.groups)],
		geolocation: data?.geolocation,
		awaitingReconsent: data?.AwaitingReconsent === 'true',
	};
}

export function generateConsentHeader(
	consentData: ConsentData | undefined,
): { 'X-Consent': string } | undefined {
	if (consentData && consentData.groups.length > 0) {
		return { 'X-Consent': `${consentData.groups.join('", "')}` };
	}

	return undefined;
}

export function sendGlobalEvent<
	N extends keyof GlobalCustomEvent,
	P extends GlobalCustomEvent[N] extends CustomEvent<infer D> ? [D] : [],
>(name: N, ...props: P) {
	globalThis.dispatchEvent(new CustomEvent(name, { detail: props[0] }));
}

interface DebouncedFunction<T extends (...args: any[]) => any> {
	(...args: Parameters<T>): ReturnType<T> | undefined;
	cancel(): void;
	flush(): ReturnType<T> | undefined;
}

/**
 * Creates a function that delays invoking `func` until after `wait`
 * milliseconds have elapsed since the last time it was invoked.
 *
 * The debounced function comes with a `cancel` method to cancel delayed
 * invocations and a `flush` method to immediately invoke them. Provide options
 * to indicate whether the function should be invoked on the leading and/or
 * trailing edge of the `wait` timeout (by default, `leading` is false and
 * `trailing` is true). The function is invoked with the last arguments
 * provided. Subsequent calls return the result of the last invocation.
 *
 * The `maxWait` option specifies the maximum time the function is allowed to
 * be delayed before it's invoked.
 *
 * If `leading` and `trailing` options are true, the function is invoked on the
 * trailing edge of the timeout only if the debounced function is invoked more
 * than once during the `wait` timeout.
 *
 * If `wait` is 0 and `leading` is false, function invocation is deferred until
 * the next tick, similar to setTimeout with a timeout of 0.
 *
 * Grabbed from lodash: https://lodash.com/docs/#debounce
 */
export function debounce<T extends (...args: any[]) => any>(
	func: T,
	wait: number,
	options: { leading?: boolean; maxWait?: number; trailing?: boolean } = {},
): DebouncedFunction<T> {
	/* eslint-disable unicorn/no-this-assignment */

	let lastArgs: any;
	let lastThis: any;
	let lastResult: any;
	let timerId: ReturnType<typeof setTimeout> | undefined;
	let lastCallTime: number | undefined;
	let lastInvokeTime = 0;
	const maxWait = is.number(options.maxWait) ? options.maxWait : 0;
	const leading = is.bool(options.leading) ? options.leading : false;
	const trailing = is.bool(options.leading) ? options.leading : true;
	const maxing = 'maxWait' in options;

	function invokeFunc(time: number) {
		const args = lastArgs;
		const thisArg = lastThis;

		lastArgs = undefined;
		lastThis = undefined;
		lastInvokeTime = time;
		lastResult = func.apply(thisArg, args);
		return lastResult;
	}

	function remainingWait(time: number) {
		const timeSinceLastCall = time - (lastCallTime || 0);
		const timeSinceLastInvoke = time - lastInvokeTime;
		const result = wait - timeSinceLastCall;

		return maxing ? Math.min(result, maxWait - timeSinceLastInvoke) : result;
	}

	function shouldInvoke(time: number) {
		const timeSinceLastCall = time - (lastCallTime || 0);
		const timeSinceLastInvoke = time - lastInvokeTime;

		// Either this is the first call, activity has stopped and we're at the
		// trailing edge, the system time has gone backwards and we're treating
		// it as the trailing edge, or we've hit the `maxWait` limit.
		return (
			lastCallTime === undefined ||
			timeSinceLastCall >= wait ||
			timeSinceLastCall < 0 ||
			(maxing && timeSinceLastInvoke >= maxWait)
		);
	}

	function trailingEdge(time: number) {
		timerId = undefined;

		// Only invoke if we have `lastArgs` which means `func` has been
		// debounced at least once.
		if (trailing && lastArgs) {
			return invokeFunc(time);
		}
		lastArgs = undefined;
		lastThis = undefined;
		return lastResult;
	}

	function timerExpired() {
		const time = Date.now();
		if (shouldInvoke(time)) {
			return trailingEdge(time);
		}
		// Restart the timer.
		timerId = setTimeout(timerExpired, remainingWait(time));
		return undefined;
	}

	function leadingEdge(time: number) {
		// Reset any `maxWait` timer.
		lastInvokeTime = time;
		// Start the timer for the trailing edge.
		timerId = setTimeout(timerExpired, wait);
		// Invoke the leading edge.
		return leading ? invokeFunc(time) : lastResult;
	}

	function cancel() {
		if (timerId !== undefined) {
			clearTimeout(timerId);
		}
		lastInvokeTime = 0;
		lastArgs = undefined;
		lastCallTime = undefined;
		lastThis = undefined;
		timerId = undefined;
	}

	function flush() {
		return timerId === undefined ? lastResult : trailingEdge(Date.now());
	}

	function debounced(this: unknown, ...args: any[]) {
		const time = Date.now();
		const isInvoking = shouldInvoke(time);

		lastArgs = args;
		lastThis = this;
		lastCallTime = time;

		if (isInvoking) {
			if (timerId === undefined) {
				return leadingEdge(lastCallTime);
			}
			if (maxing) {
				// Handle invocations in a tight loop.
				timerId = setTimeout(timerExpired, wait);
				return invokeFunc(lastCallTime);
			}
		}
		if (timerId === undefined) {
			timerId = setTimeout(timerExpired, wait);
		}
		return lastResult;
	}

	debounced.cancel = cancel;
	debounced.flush = flush;

	return debounced;
}

type ThrottledFunction<T extends (...args: any[]) => any> =
	DebouncedFunction<T>;

/**
 * Creates a function that only invokes `func` at most once per every `wait`
 * milliseconds.
 *
 * The throttled function comes with a `cancel` method to cancel delayed
 * invocations and a `flush` method to immediately invoke them. Provide options
 * to indicate whether the function should be invoked on the leading and/or
 * trailing edge of the `wait` timeout (by default, `leading` is false and
 * `trailing` is true). The function is invoked with the last arguments
 * provided. Subsequent calls return the result of the last invocation.
 *
 * If `leading` and `trailing` options are true, the function is invoked on the
 * trailing edge of the timeout only if the throttled function is invoked more
 * than once during the `wait` timeout.
 *
 * If `wait` is 0 and `leading` is false, function invocation is deferred until
 * the next tick, similar to setTimeout with a timeout of 0.
 *
 * Grabbed from lodash: https://lodash.com/docs/#throttle
 */
export function throttle<T extends (...args: any[]) => any>(
	func: T,
	wait: number,
	options: { leading?: boolean; trailing?: boolean } = {},
): ThrottledFunction<T> {
	const leading = is.bool(options.leading) ? options.leading : true;
	const trailing = is.bool(options.leading) ? options.leading : true;

	return debounce(func, wait, {
		leading,
		maxWait: wait,
		trailing,
	});
}

/**
 * Run a function after the next browser repaint.
 */
export function afterNextPaint(callback: () => void, times: number = 1) {
	// requestAnimationFrame runs in the same frame before the browser paints and
	// any code called there will run right away, unless it's a task scheduling
	// call like another rAF which will then be queued to the next frame.
	requestAnimationFrame(() => {
		requestAnimationFrame(() => {
			if (times > 1) {
				afterNextPaint(callback, times - 1);
			} else {
				callback();
			}
		});
	});
}

/**
 * Get a promise that's resolved after the next browser repaint (async version
 * of `afterNextPaint`).
 */
export function nextPaint(times: number = 1): Promise<void> {
	return new Promise((resolve) => {
		afterNextPaint(() => {
			resolve();
		}, times);
	});
}

/**
 * Check for exhaustive switch statements.
 *
 * @example
 *
 * const mood: 'good' | 'bad' = getMood();
 * switch (mood) {
 *   case 'good':
 *     return 'Nice!';
 *   default:
 *     // Missing case for 'bad'. TS error:
 *     // Argument of type 'string' is not assignable to parameter of type 'never'
 *     return assertUnreachable(mood, 'mood');
 * }
 */
export function assertUnreachable(
	val: never,
	variableName: string = 'value',
): never {
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	throw new Error(`Unexpected ${variableName} '${val}'`);
}

/**
 * Add a catch handler that does nothing to a promise.
 */
export function ignorePromiseRejection(val: Promise<unknown>) {
	val.catch(empty.func);
}

/**
 * Extract a layout service component's data.
 */
export function getLayoutServiceComponentFields<T>(
	data: CustomLayoutServiceData | undefined,
	placeholder: LayoutServicePlaceholder,
	componentName: string,
) {
	const component = data?.sitecore.route?.placeholders?.[placeholder]?.find(
		(item) => 'componentName' in item && item.componentName === componentName,
	) as { fields?: T } | undefined;
	return component?.fields;
}

export function getTestDataAttrFrom(value: string | undefined) {
	const env = publicRuntimeConfig?.ENVIRONMENT_TYPE;
	if (env !== 'production' && value) {
		return value;
	}
	return undefined;
}

/**
 * Check if currently running on the client rather than the server.
 */
export function isClient() {
	// eslint-disable-next-line unicorn/prefer-global-this
	return typeof window !== 'undefined';
}
