/**
 * Price summaries
 */
import React from 'react';

import Icon from 'components/Icon';
import PriceRow from 'components/PriceRow';
import Text from 'components/Text';
import TotalWithVat from 'components/TotalWithVat';
import { DiscountCode, GiftCard, Summary } from 'models/api';
import { cn } from 'utils/classNames';
import { formatPriceText } from 'utils/format';
import { useI18n } from 'utils/i18n';

interface Props {
	/** If we have discount codes in the summary the discount codes added to the cart are needed to render their numbers */
	bonusDiscounts: DiscountCode[] | undefined;
	/** Possible styling, probably best used to position the component with */
	className?: string;
	/** If we have gift cards in the summary the gift cards added to the cart are needed to render their numbers */
	giftCards: GiftCard[] | undefined;
	/** Added last to sum up possible savings */
	savingTotalSumSummary: Summary | undefined;
	/** All the summaries to be rendered, rendered as they appear in the array */
	summaries: Summary[] | undefined;

	summaryLargeTitle?: boolean;
}

/** Component that renders summaries from the cart, different summaries can have different appearances */
export default function PriceSummaries({
	bonusDiscounts,
	className,
	giftCards,
	savingTotalSumSummary,
	summaries,
	summaryLargeTitle,
}: Props) {
	const { t } = useI18n();
	return (
		<div className={cn(className)}>
			{summaries?.map((summary, index) => {
				const variant = summary?.value >= 0 ? 'regular' : 'minusPrice';
				const key = `${summary.sumType}-${index}`;
				switch (summary.sumType) {
					case 'Vat':
						return null;
					case 'AmountToPay': {
						const vat = summaries?.find(({ sumType }) => sumType === 'Vat');
						return (
							<TotalWithVat
								key={key}
								className="mt-3"
								title={summary.title}
								price={summary.displayValue}
								priceSymbol={summary.displaySymbol}
								vat={vat?.displayValue}
								vatCurrencySymbol={vat?.displaySymbol}
								hasLargeTitle={summaryLargeTitle}
							/>
						);
					}
					case 'GiftCard':
						return (
							<PriceRow
								key={key}
								title={summary.title}
								subtitle={summary.description}
								price={summary.displayValue}
								priceSymbol={summary.displaySymbol}
								variant={variant}
							>
								{giftCards &&
									giftCards.length > 0 &&
									giftCards.map((giftCard) => (
										<Text
											key={giftCard.id}
											as="pSmall"
											className="text-greyDarker"
										>
											{t('checkout_number_label')} {giftCard.id}
										</Text>
									))}
							</PriceRow>
						);
					case 'BonusCheck':
						return (
							<PriceRow
								key={key}
								title={summary.title}
								subtitle={summary.description}
								price={summary.displayValue}
								priceSymbol={summary.displaySymbol}
								variant={variant}
							>
								{bonusDiscounts &&
									bonusDiscounts.length > 0 &&
									bonusDiscounts.map((bonusCheck) => (
										<Text
											key={bonusCheck.checkNumber}
											as="pSmall"
											className="text-greyDarker"
										>
											{t('checkout_number_label')} {bonusCheck.checkNumber}
										</Text>
									))}
							</PriceRow>
						);
					default:
						return (
							<PriceRow
								key={key}
								title={summary.title}
								subtitle={summary.description}
								price={summary.displayValue}
								priceSymbol={summary.displaySymbol}
								variant={variant}
							/>
						);
				}
			})}
			{savingTotalSumSummary && (
				<div className="mt-4 flex items-center justify-end">
					<Icon
						name="offer"
						className="mr-4"
						color="julaRed"
						size={16}
						aria-hidden
					/>
					<Text as="p" className="text-julaRed">
						{savingTotalSumSummary.title}{' '}
						{formatPriceText(
							savingTotalSumSummary.displayValue,
							savingTotalSumSummary.displaySymbol,
						)}
					</Text>
				</div>
			)}
		</div>
	);
}
PriceSummaries.displayName = 'PriceSummaries';
