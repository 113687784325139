/**
 * BlockPromoStartPage
 */

import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ArrowLink from 'components/ArrowLink';
import BlockMedia from 'components/BlockMedia';
import Chip from 'components/Chip';
import Icon from 'components/Icon';
import Img from 'components/Img';
import Link from 'components/Link';
import Text from 'components/Text';
import type { HeadingLevel } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import type { SimpleLink } from 'models/sitecore';
import { cn } from 'utils/classNames';
import type { GTMItemListId, GTMItemListName } from 'utils/GoogleTagManager';
import { is } from 'utils/helpers';

interface CategoryIcon {
	iconUrl?: string;
	title: string;
	url: string;
}

interface SupportItem {
	campaignBubbleLabel?: string;
	campaignBubbleText?: string;
	id: string;
	imageAlt?: string;
	imageSrc?: string;
	link?: SimpleLink;
	video?: DigizuiteAsset;
}

interface Props {
	basePriceTextBackground?: string;
	campaignBubbleLabel?: string;
	campaignBubbleText?: string;
	categoryIcons?: CategoryIcon[];
	gtmItemListId: GTMItemListId;
	gtmItemListName: GTMItemListName;
	heading?: Field<string>;
	headingLevel?: HeadingLevel;
	preventAutoplay?: boolean;
	primaryLargeImageAlt: string | undefined;
	primaryLargeImageSrc: string | undefined;
	primaryLargeVideo?: DigizuiteAsset;
	primaryLinkHref?: string;
	primaryLinks?: SimpleLink[];
	primarySmallImageAlt: string | undefined;
	primarySmallImageSrc: string | undefined;
	primarySmallVideo?: DigizuiteAsset;
	products?: ProductCard[];
	supportItems: SupportItem[];
}

/** The two smaller items with up to a single link each. */
function SupportItems({
	className,
	imgClassName,
	items,
}: {
	className?: string;
	imgClassName?: string;
	items: SupportItem[];
}) {
	return (
		<>
			{items.map(
				({
					campaignBubbleLabel,
					campaignBubbleText,
					id,
					imageAlt,
					imageSrc,
					link,
					video,
				}) => (
					<div key={id} className={cn('relative', className)}>
						<BlockMedia
							variant="landscapeSmall"
							className="md:h-full"
							imgClassName={imgClassName}
							imgSrc={imageSrc}
							imgAlt={imageAlt}
							video={video}
							href={link?.href}
							mediaWrapperClassName="md:h-full"
							campaignBubbleLabel={campaignBubbleLabel}
							campaignBubbleText={campaignBubbleText}
						/>
						{link?.href && link.text && (
							<>
								<Chip
									color="grey"
									border={false}
									href={link.href}
									text={link.text}
									className="absolute bottom-6 left-6 max-md:hidden"
								/>
								<ArrowLink
									href={link.href}
									text={link.text}
									className="mt-3 md:hidden"
								/>
							</>
						)}
					</div>
				),
			)}
		</>
	);
}
SupportItems.displayName = 'BlockPromoStartPage_SupportItems';

function PrimaryLinks({
	className,
	links,
}: {
	className: string;
	links: SimpleLink[] | undefined;
}) {
	if (!is.arrayWithLength(links)) {
		return null;
	}
	return (
		<div className={className}>
			{links.map((link) => (
				<Chip
					key={link.href}
					color="grey"
					href={link.href}
					text={link.text}
					target={link.target}
				/>
			))}
		</div>
	);
}

PrimaryLinks.displayName = 'BlockPromoStartPage_PrimaryLinks';

export default function BlockPromoStartPage({
	basePriceTextBackground,
	campaignBubbleLabel,
	campaignBubbleText,
	categoryIcons,
	gtmItemListId,
	gtmItemListName,
	heading,
	headingLevel = 'h2',
	preventAutoplay,
	primaryLargeImageAlt,
	primaryLargeImageSrc,
	primaryLargeVideo,
	primaryLinkHref,
	primaryLinks,
	primarySmallImageAlt,
	primarySmallImageSrc,
	primarySmallVideo,
	products,
	supportItems,
}: Props) {
	return (
		<>
			<div className="md:grid md:grid-cols-12 md:gap-6">
				<div className="relative md:col-span-8 md:row-span-2">
					<BlockMedia
						imageLoading="eager"
						variant="landscape"
						video={primaryLargeVideo}
						imgSrc={primaryLargeImageSrc}
						imgAlt={primaryLargeImageAlt}
						href={primaryLinkHref}
						mediaWrapperClassName="h-full"
						campaignBubbleLabel={campaignBubbleLabel}
						campaignBubbleText={campaignBubbleText}
						products={products}
						productsDisplayType="campaignSpinner"
						preventAutoplay={preventAutoplay}
						basePriceTextBackground={basePriceTextBackground}
						className="h-full overflow-hidden max-sm:hidden"
						imgClassName="h-full w-full object-cover"
						productAnimationPriceSize="medium"
						productAnimationPlacementClassName="absolute bottom-20 top-20 right-4 w-1/2 flex justify-center"
						gtmItemListId={gtmItemListId}
						gtmItemListName={gtmItemListName}
						beforeButtonsContent={
							<PrimaryLinks
								links={primaryLinks}
								className="absolute flex flex-wrap items-start gap-4 max-md:hidden md:bottom-6 md:left-6 md:right-6"
							/>
						}
					/>
					<BlockMedia
						imagePriority
						variant="landscapeTall"
						video={primarySmallVideo}
						imgSrc={primarySmallImageSrc}
						imgAlt={primarySmallImageAlt}
						href={primaryLinkHref}
						mediaWrapperClassName="h-full"
						campaignBubbleLabel={campaignBubbleLabel}
						campaignBubbleText={campaignBubbleText}
						products={products}
						productsDisplayType="campaignSpinner"
						preventAutoplay={preventAutoplay}
						basePriceTextBackground={basePriceTextBackground}
						className="-mx-4 overflow-hidden sm:hidden"
						productAnimationPlacementClassName="absolute bottom-8 top-16 left-1/2 -translate-x-1/2 flex justify-center"
						gtmItemListId={gtmItemListId}
						gtmItemListName={gtmItemListName}
					/>
					<PrimaryLinks
						className="mt-4 flex flex-wrap items-start gap-4 max-sm:flex-col md:hidden"
						links={primaryLinks}
					/>
				</div>
				<SupportItems
					items={supportItems}
					className="col-span-4 max-md:hidden"
					imgClassName="h-full w-full object-cover"
				/>
			</div>

			{is.arrayWithLength(categoryIcons) && (
				<>
					<Text
						as={headingLevel}
						styleAs="h1"
						field={heading}
						className="mt-8"
					/>
					<ul className="mt-6 grid auto-rows-fr grid-cols-3 gap-y-6 sm:grid-cols-4 md:grid-cols-6 lg:flex lg:justify-center">
						{categoryIcons.map(({ iconUrl, title, url }) => (
							<li key={`${url}-${title}`} className="text-center lg:flex-1">
								<Link
									href={url}
									className="group inline-flex flex-col items-center px-1 text-center"
								>
									{iconUrl ? (
										<Img
											src={iconUrl}
											width={48}
											height={48}
											jpgOptimized={false}
											service="nextjs"
											loading="eager"
											className="size-12"
										/>
									) : (
										<Icon
											name="star"
											size={null}
											className="size-8 md:size-12"
										/>
									)}
									<span className="mt-2 min-w-[6em] font-standard text-sm group-hover:underline md:text-base">
										{title}
									</span>
								</Link>
							</li>
						))}
					</ul>
				</>
			)}

			<div className="mt-8 grid gap-6 sm:grid-cols-2 md:hidden">
				<SupportItems items={supportItems} imgClassName="w-full" />
			</div>
		</>
	);
}
BlockPromoStartPage.displayName = 'BlockPromoStartPage';
