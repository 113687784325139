import React from 'react';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Button from 'components/Button';
import GenericForm from 'components/GenericForm';
import Icon from 'components/Icon';
import { Step, Stepper } from 'components/Stepper';
import Text from 'components/Text';
import { publicRuntimeConfig } from 'config';
import type { FormFields, Section } from 'models/sitecore';
import type {
	CustomerInfo,
	Worksite,
} from 'state-machines/createJulaProCustomer';
import type { FormattedValidationErrors } from 'utils/fetchData';
import { formatPrice } from 'utils/format';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	addCustomerButtonState: ActionButtonState;
	creditFunctionsEnabled: boolean;
	customerInfo: CustomerInfo | null;
	resetFromStepThree: () => void;
	section: Section | undefined;
	selectedCredit: number | null;
	selectedWorksite: Worksite | undefined;
	submitBecomeProMemberForm: (
		form: FormFields,
		values: Record<string, unknown>,
	) => Promise<FormattedValidationErrors['fieldValidationErrors']>;
}

export default function StepThreeContent({
	addCustomerButtonState,
	creditFunctionsEnabled,
	customerInfo,
	resetFromStepThree,
	section,
	selectedCredit,
	selectedWorksite,
	submitBecomeProMemberForm,
}: Props) {
	const { t } = useI18n();

	if (!section) {
		return null;
	}

	const { backText, form, submitText } = section;

	return (
		<>
			{creditFunctionsEnabled && (
				<Stepper className="mx-8 mb-8 mt-4 flex items-center">
					<Step number={1} status="done" />
					<Step number={2} status="done" />
					<Step number={3} status="active" />
				</Stepper>
			)}

			<Text as="h3">{t('julapro_stepper_step_three_oranization_heading')}</Text>

			<p className="mt-1">
				{selectedWorksite?.companyName}
				<br />
				{selectedWorksite?.organizationNumber}
			</p>

			<p className="mt-4">
				{selectedWorksite?.address}
				<br />
				{`${selectedWorksite?.postalNumber}, ${selectedWorksite?.city}`}
			</p>

			{is.defined(selectedCredit) && selectedCredit > 0 && (
				<p className="mt-4">
					{t('julapro_wished_creditlevel')}{' '}
					<span className="font-bold">
						{formatPrice(selectedCredit, 'regular')}.-
					</span>
				</p>
			)}

			<Text as="h3" className="mt-8">
				{t('julapro_stepper_step_three_admin_info_heading')}
			</Text>
			{customerInfo && (
				<p className="mt-1">
					{customerInfo?.name}
					<br />
					{customerInfo?.contactId}
				</p>
			)}
			<GenericForm
				inputSpace="my-4"
				fields={form}
				initialValues={{ offers: false, termsAndConditionsAccepted: false }}
				onSubmitCallback={(values) => submitBecomeProMemberForm(form, values)}
				button={
					<ActionButton
						data-cid="julaproBecomeMember"
						displayWidth="full"
						type="submit"
						size="large"
						variant="cta"
						customState={addCustomerButtonState}
						className="mt-6"
					>
						{publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_CODE === 'SE' && (
							<Icon name="bankIdSe" color="white" className="mr-2" />
						)}
						{submitText.value}
					</ActionButton>
				}
			/>
			<div className="mt-4 flex justify-center">
				<Button variant="text" onClick={resetFromStepThree}>
					{backText.value}
				</Button>
			</div>
		</>
	);
}
StepThreeContent.displayName = 'AccountJulaProSignupForm_StepThreeContent';
