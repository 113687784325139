import React, { type ReactNode } from 'react';
import {
	type ComponentPropsCollection,
	ComponentPropsContext,
	SitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { SWRConfig } from 'swr';

import type { CustomLayoutServiceData, UserData } from 'lib/page-props';
import { componentBuilder } from 'temp/componentBuilder';
import { type Dictionary, I18nProvider } from 'utils/i18n';

import { ChatContextProvider } from './ChatContextProvider';
import { FeatureToggleContextProvider } from './FeatureToggleContextProvider';
import { GlobalMachinesContextProvider } from './GlobalMachinesContextProvider';
import { SelectedStoreContextProvider } from './SelectedStoreContextProvider';
import { SelectedStoreInfoContextProvider } from './SelectedStoreInfoContextProvider';

interface Props {
	children: ReactNode;
	componentProps: ComponentPropsCollection;
	dictionary: Dictionary | undefined;
	layoutData: CustomLayoutServiceData;
	userData: UserData | undefined;
}

export default function GlobalContexts({
	children,
	componentProps,
	dictionary,
	layoutData,
	userData,
}: Props) {
	const isEditing = layoutData.sitecore.context.pageEditing;
	const sitecoreContext = layoutData.sitecore.context;
	return (
		<SelectedStoreContextProvider
			initialSelectedStore={userData?.selectedStore}
		>
			<SelectedStoreInfoContextProvider>
				<FeatureToggleContextProvider
					featureToggles={sitecoreContext.featureToggles}
				>
					<GlobalMachinesContextProvider sitecoreContext={sitecoreContext}>
						<ChatContextProvider>
							<SWRConfig value={{ loadingTimeout: 10_000 }}>
								<I18nProvider dictionary={dictionary}>
									<ComponentPropsContext value={componentProps}>
										<SitecoreContext
											componentFactory={componentBuilder.getComponentFactory({
												isEditing,
											})}
											layoutData={layoutData}
										>
											{children}
										</SitecoreContext>
									</ComponentPropsContext>
								</I18nProvider>
							</SWRConfig>
						</ChatContextProvider>
					</GlobalMachinesContextProvider>
				</FeatureToggleContextProvider>
			</SelectedStoreInfoContextProvider>
		</SelectedStoreContextProvider>
	);
}
GlobalContexts.displayName = 'GlobalContexts';
