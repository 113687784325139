import React, { type FocusEventHandler, useState } from 'react';

import Icon from 'components/Icon';
import { LayoutContainer } from 'components/Layout';
import Link, { OptionalLink } from 'components/Link';
import Rating from 'components/Rating';
import { useMaxWidth, useTransitionInterval } from 'hooks';
import type { Link as LinkType, Review, USP } from 'models/sitecore';
import { cn } from 'utils/classNames';
import { findNewIndex } from 'utils/collection';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

const USP_INTERVAL = 5000;

interface Props {
	/** Menu links */
	menuLinks?: LinkType[];
	/** Jula ecommerce page review data */
	review?: Review | undefined;
	/** USP texts */
	uspLinks?: USP[];
}

/** Red header top bar */
export default function PageHeaderTopBar({
	menuLinks,
	review,
	uspLinks,
}: Props) {
	const { t } = useI18n();
	const usps = uspLinks ? uspLinks.filter((usp) => usp.fields?.text) : [];
	const uspCount = usps.length;
	const [uspInterval, setUspInterval] = useState<number | null>(USP_INTERVAL);
	const [currentUspIndex, setCurrentUspIndex] = useState(0);
	const nextUspIndex = findNewIndex(usps, currentUspIndex, 'next');
	const isSmallScreen = useMaxWidth('sm');

	const isTransitioning = useTransitionInterval(
		() => {
			setCurrentUspIndex((current) => findNewIndex(usps, current, 'next'));
		},
		isSmallScreen && uspCount > 1 ? uspInterval : null,
		150,
	);

	// Pause interval when USP links receive keyboard focus, to avoid losing
	// that focus by the active USP changing.
	const handleUspListFocus: FocusEventHandler = () => {
		setUspInterval(null);
	};
	const handleUspListBlur: FocusEventHandler = () => {
		setUspInterval(USP_INTERVAL);
	};

	return (
		<div className="page-header-part relative flex min-h-[3rem] bg-julaRed py-2 text-sm text-white sm:items-center md:min-h-[3.5rem] md:text-base">
			<LayoutContainer
				outerClassName="grow max-sm:flex"
				className="flex items-center gap-x-3 max-sm:w-full sm:gap-x-6"
			>
				{is.arrayWithLength(usps) && (
					<ul
						className={cn(
							// NOTE! Keep the calc rem addition in sync with the vertical
							// padding on the outer container.
							'relative overflow-hidden leading-snug max-sm:h-[calc(100%+1rem)] max-sm:w-full',
							'sm:flex sm:flex-wrap sm:gap-x-6',
						)}
						onFocus={handleUspListFocus}
						onBlur={handleUspListBlur}
					>
						{usps.map((usp, i) => {
							const { link, text } = usp.fields;
							const isCurrent = i === currentUspIndex;
							const isNext = i === nextUspIndex;
							const isHidden = !isCurrent;

							const content = (
								<>
									<Icon name="check" className="max-sm:hidden" />
									<span
										className={cn(
											'font-semibold sm:inline-block sm:align-middle',
											link && 'group-hover:underline',
										)}
									>
										{text}
									</span>
								</>
							);

							return (
								<li
									key={usp.name}
									aria-hidden={isHidden}
									className={cn(
										// Visually align the left edge of the check icon
										'sm:-ml-[5px]',
										'max-sm:flex max-sm:h-full max-sm:w-full max-sm:items-center',
										'max-sm:absolute max-sm:left-0 max-sm:top-0 max-sm:transition-fadeTransform',
										uspCount > 2 && [
											'max-sm:duration-500',
											// The currenly visible static USP
											isCurrent && 'max-sm:translate-y-0',
											// The next visible USP moved to bottom in preparation of
											// sliding up
											isNext && '!duration-0 max-sm:translate-y-full',
											// The just visible USP sliding up and away, and any
											// other previus USPs staying up there
											!isCurrent && !isNext && 'max-sm:-translate-y-full',
										],
										// Unlikely to happen but support to keep the component
										// robust. Just do a fade since sliding up and then being
										// ready from below requires two steps, which means either
										// additional state or an animation instead of transition.
										uspCount === 2 && [
											'max-sm:duration-300',
											(!isCurrent || isTransitioning) && 'max-sm:opacity-0',
										],
									)}
								>
									{!link?.href && content}
									{link?.href && (
										<Link
											href={link.href}
											target={link.target}
											className="group inline-block"
											tabIndex={isHidden ? -1 : undefined}
										>
											{content}
										</Link>
									)}
								</li>
							);
						})}
					</ul>
				)}

				{is.arrayWithLength(menuLinks) && (
					<nav
						className="ml-auto max-md:hidden"
						aria-label={t('header_top_bar_menu_label')}
					>
						<ul className="flex flex-wrap gap-x-6 gap-y-2">
							{menuLinks.map(({ fields }) => {
								if (!fields?.link?.href) {
									return null;
								}
								return (
									<li key={fields.link.id || fields.link.text}>
										<Link href={fields.link.href} className="hover:underline">
											{fields.link.text}
										</Link>
									</li>
								);
							})}
						</ul>
					</nav>
				)}
				{review && (
					<OptionalLink
						href={review?.reviewLink}
						linkClassName="hover:bg-greyLight"
						className="inline-flex items-center rounded-full bg-white px-1.5 py-1 text-black max-md:ml-auto md:px-4 md:py-2"
					>
						<Rating
							className="md:hidden"
							allowWrapping={false}
							reviewCount={review.numberOfReviews}
							hasVisibleScore
							reviewCountVariant="hidden"
							score={review.averageStars}
							size="small"
						/>
						<Rating
							className="max-md:hidden"
							allowWrapping={false}
							hasVisibleScore
							reviewCount={review.numberOfReviews}
							score={review.averageStars}
						/>
					</OptionalLink>
				)}
			</LayoutContainer>
		</div>
	);
}
PageHeaderTopBar.displayName = 'PageHeaderTopBar';
