/**
 * ProductAnimation
 */

import React, { useState } from 'react';

import Img from 'components/Img';
import { OptionalLink } from 'components/Link';
import Price, { BasePriceText } from 'components/Price';
import Text from 'components/Text';
import { useInterval } from 'hooks';
import { type PriceSize } from 'models/price';
import { ProductCard } from 'models/productCard';
import { getProductCardImage } from 'utils/business-logic';
import { cn, cnm } from 'utils/classNames';
import { findNewIndex } from 'utils/collection';

export interface Props {
	/** Background color for any base price text */
	basePriceTextBackground?: string;

	/** Extra container class names. */
	className?: string;

	/** If the animation container should cover (and stay within) available height. */
	coverHeight?: boolean;

	/** Product link click handler. */
	onProductClick?: (product: ProductCard, index: number) => void;

	/** Size of price label. */
	priceSize?: PriceSize;

	/** Products to animate. */
	products: ProductCard[];

	/** Play the animation? */
	shouldPlay?: boolean;

	/** Show the product name below the image? */
	showTitle?: boolean;
}

/** An animated loop of linked product images. */
export default function ProductAnimation({
	basePriceTextBackground,
	className,
	coverHeight = false,
	onProductClick,
	priceSize = 'small',
	products,
	shouldPlay = true,
	showTitle = false,
}: Props) {
	const [currentIndex, setCurrentIndex] = useState(0);
	const prevIndex = findNewIndex(products, currentIndex, 'prev');
	const nextIndex = findNewIndex(products, currentIndex, 'next');

	useInterval(
		() => {
			setCurrentIndex((i) => findNewIndex(products, i, 'next'));
		},
		shouldPlay ? 5000 : null,
	);

	const imgClassName = cn(
		coverHeight && 'aspect-square h-auto w-auto max-w-full',
		coverHeight && !showTitle && 'max-h-full',
		// Approximate height for a single line of text, if the title
		// can be more than one line where this animation is used it
		// should take overflow into account.
		coverHeight && showTitle && 'max-h-[calc(100%-1.5rem)]',
	);

	return (
		<div
			className={cnm(
				'relative flex aspect-square justify-center',
				coverHeight && 'h-full',
				className,
			)}
		>
			{products.map((product, i) => (
				<OptionalLink
					key={product.id}
					href={product.url}
					onClick={
						onProductClick
							? () => {
									onProductClick(product, i);
								}
							: undefined
					}
					className={cn(
						// Parent components, like <BlockMedia/>, might set pointer events none
						// add back auto to make links clickable.
						'pointer-events-auto absolute top-0 inline-flex items-center justify-center transition-visibility duration-0 sm:justify-end',
						coverHeight && 'h-full',
						i !== currentIndex && 'invisible',
						i === prevIndex && 'delay-1000',
					)}
				>
					<div
						className={cn(
							'flex origin-[center_left] flex-col items-center justify-center transition-fadeTransform duration-700 sm:items-end',
							coverHeight && 'h-full',
							i !== currentIndex && 'opacity-0',
							i === currentIndex && 'translate-x-0 delay-[400ms]',
							i === nextIndex && 'translate-x-16',
							i === prevIndex && '-translate-x-10 scale-[0.6] duration-1000',
						)}
					>
						{[
							{ size: 393, cls: 'max-sm:hidden' },
							{ size: 192, cls: 'sm:hidden' },
						].map(({ cls, size }) => (
							<Img
								key={size}
								src={getProductCardImage(product)}
								useFallbackOnError
								className={cn(imgClassName, cls)}
								width={size}
								height={size}
								service="nextjs"
								jpgOptimized={false}
								alt={showTitle ? '' : product.title}
							/>
						))}
						{showTitle && (
							<Text
								className="self-center text-center text-white [text-shadow:0_1px_2px_rgba(0,0,0,0.2)]"
								as="p"
							>
								{product.title}
							</Text>
						)}
					</div>
					{product.displayPrice && (
						<div
							className={cn(
								'absolute transition-fadeTransform duration-700',
								'max-sm:-left-12 max-sm:bottom-4',
								'sm:right-0 sm:top-2',
								i !== currentIndex && 'opacity-0',
								i === currentIndex && 'translate-y-0 delay-[400ms]',
								i === nextIndex && '-translate-y-4',
								i === prevIndex && 'translate-y-2 scale-75',
							)}
						>
							<div className="flex flex-col items-end">
								<Price price={product.displayPrice} size={priceSize} />
								<div
									className={cn(
										'absolute w-max max-w-[9rem] text-balance text-xs text-white',
										'max-sm:left-0 max-sm:top-full max-sm:mt-1',
										'sm:bottom-full sm:right-0 sm:mb-3 sm:text-right',
									)}
								>
									<BasePriceText
										basePrice={product.basePrice}
										displayedPriceType={product.displayPrice.priceType}
										recentLowestPriceKey="product_animation_recent_lowest_price_text"
										style={
											basePriceTextBackground
												? { backgroundColor: basePriceTextBackground }
												: undefined
										}
										className={cn(
											!basePriceTextBackground && 'shadow-black text-shadow',
											basePriceTextBackground &&
												'inline box-decoration-clone px-[0.1875rem] py-px shadow-[rgba(0,0,0,0.5)] text-shadow-sm',
										)}
									/>
								</div>
							</div>
						</div>
					)}
				</OptionalLink>
			))}
		</div>
	);
}
ProductAnimation.displayName = 'ProductAnimation';
