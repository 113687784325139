/**
 * ShortcutImage
 */

import React, { type AnchorHTMLAttributes } from 'react';

import Icon from 'components/Icon';
import Img from 'components/Img';
import Link from 'components/Link';
import Text from 'components/Text';
import { cn } from 'utils/classNames';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
	className?: string;
	hideIconWhenSmall?: boolean;
	href: string;
	imgHeight: number;
	imgSrc: string;
	imgWidth: number;
	text: string;
}

export default function ShortcutImage({
	className,
	hideIconWhenSmall = false,
	href,
	imgHeight,
	imgSrc,
	imgWidth,
	text,
	...attrs
}: Props) {
	return (
		<Link
			{...attrs}
			href={href}
			className={cn('group/shortcut-image inline-block', className)}
		>
			{/* Skip alt text on purpose since the link has a proper text below. */}
			<Img
				src={imgSrc}
				width={imgWidth}
				height={imgHeight}
				service="nextjs"
				className="mb-2 w-full"
			/>
			<Text as="span" className="flex items-start">
				<Icon
					name="arrow"
					backgroundColor="julaRed"
					color="white"
					className={cn(
						'mr-2 group-hover/shortcut-image:bg-julaRedDark',
						hideIconWhenSmall && 'max-sm:hidden',
					)}
				/>
				<span className="group-hover/shortcut-image:underline">{text}</span>
			</Text>
		</Link>
	);
}
ShortcutImage.displayName = 'BlockShortcutImages_ShortcutImage';
