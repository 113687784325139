import { parse as parseCookie } from 'cookie';
import { waitFor } from 'xstate/lib/waitFor';

import { publicRuntimeConfig } from 'config';
import { ResponseError, ValidationError } from 'errors';
import type { JulaValidationError } from 'models/api';
import type { JSONValue } from 'types';
import {
	generateConsentHeader,
	is,
	isClient,
	parseConsentString,
	sendGlobalEvent,
} from 'utils/helpers';
import { log } from 'utils/log';
import { failure, type Result, success } from 'utils/result';

export const JSON_MIME_TYPE = 'application/json';

/**
 * Ensure the user has a valid token.
 */
export async function ensureFreshToken() {
	if (isClient() && globalThis.globalFetchLockInterpreter) {
		const tokenExpiry = globalThis.localStorage.getItem('tokenExpiry');
		if (!tokenExpiry || Date.now() > Number.parseInt(tokenExpiry, 10)) {
			sendGlobalEvent('refresh-token');
		}
		await waitFor(
			globalThis.globalFetchLockInterpreter,
			(state) => state.matches('unlocked'),
			{ timeout: 20_000 },
		);
	}
}

/**
 * Check if a request or response is a JSON content type.
 */
function isJson(headers: HeadersInit | Headers): boolean {
	const contentType =
		// Can't use instanceof due to tests, the global Headers here isn't
		// the same as the one used for mocking.
		// eslint-disable-next-line @typescript-eslint/unbound-method
		'get' in headers && is.func(headers.get)
			? headers.get('Content-Type')
			: headers['Content-Type'];
	// Use includes since Content-Type can contain things like charset as well.
	return is.string(contentType) && contentType.includes(JSON_MIME_TYPE);
}

interface RequestOptions extends Omit<RequestInit, 'body'> {
	/** The request body. JSON-valid values will be stringified. */
	body?: BodyInit | JSONValue;
	// Disallow the tuple array and Headers instance formats here for easier
	// reading internally.
	/** An object literal with request headers. */
	headers?: Record<string, string>;
	/** How many times to attempt a request in case it fails. */
	maxAttempts?: number;
}

async function fetcher<T = unknown>(
	input: string | URL | Request,
	options?: RequestOptions,
	attempt = 1,
): Promise<T | undefined> {
	const maxAttempts = options?.maxAttempts ?? 3;
	const customHeaders = { ...options?.headers };
	const headers: HeadersInit = {
		...customHeaders,
		'Pragma': customHeaders.Pragma || 'no-cache',
		'Cache-Control': customHeaders['Cache-Control'] || 'no-cache',
		'Content-Type': customHeaders['Content-Type'] || JSON_MIME_TYPE,
		'CF-Access-Client-Id': String(
			publicRuntimeConfig?.NEXT_PUBLIC_CF_ACCESS_CLIENT_ID,
		),
		'CF-Access-Client-Secret': String(
			publicRuntimeConfig?.NEXT_PUBLIC_CF_ACCESS_CLIENT_SECRET,
		),
		...generateConsentHeader(
			parseConsentString(
				typeof document === 'undefined'
					? undefined
					: parseCookie(document.cookie).OptanonConsent,
			),
		),
	};

	// When sending form data, the browser itself has to set the Content-Type
	// header for a proper boundary to be included.
	// https://stackoverflow.com/q/39280438
	if (options?.body instanceof FormData) {
		delete headers['Content-Type'];
	}

	let body: BodyInit | undefined;
	if (is.defined(options?.body)) {
		const opt = options.body;
		// Exclude string to not double stringify.
		const isJsonValue =
			is.plainObject(opt) || is.array(opt) || is.bool(opt) || is.number(opt);
		body = isJsonValue ? JSON.stringify(opt) : opt;
	}

	await ensureFreshToken();

	const response = await fetch(input, {
		...options,
		cache: options?.cache || 'no-store',
		credentials: options?.credentials || 'include',
		headers,
		body,
	});

	// response.json() will throw a parse error for empty responses so always
	// read as text and manually parse to JSON to handle it.
	const responseText = await response.text();
	let responseData: JSONValue | undefined;
	if (isJson(response.headers)) {
		responseData =
			!responseText || responseText === 'null'
				? undefined
				: JSON.parse(responseText);
	} else {
		responseData = responseText || undefined;
	}

	if (response.ok) {
		// HTTP 204 is No Content. Also resolve any empty values as undefined.
		if (response.status === 204 || is.nonZeroFalsy(responseData)) {
			return undefined;
		}
		if (is.numeric(responseData)) {
			return Number(responseData) as T;
		}
		return responseData as T;
	}

	if (
		response.status === 400 &&
		responseData &&
		is.object(responseData) &&
		'errors' in responseData &&
		is.array(responseData.errors)
	) {
		throw new ValidationError(responseData as JulaValidationError);
	}

	// For 401 Unauthorized responses, try to refresh the user's access token
	// and run the request again.
	if (response.status === 401) {
		if (maxAttempts > 0 && attempt < maxAttempts) {
			sendGlobalEvent('refresh-token');
			return fetcher(input, options, attempt + 1);
		}
		sendGlobalEvent('logout');
		log.warning(
			// Unlikely to be anything other than a string.
			// eslint-disable-next-line @typescript-eslint/no-base-to-string
			`401 Unauthorized at ${input.toString()}: refreshing token and retrying request failed after ${maxAttempts} attempts, logging out user`,
		);
	}

	throw new ResponseError(
		response.headers,
		response.status,
		response.statusText,
		responseData,
		'Unknown error',
	);
}

/**
 * Do a request and get the parsed response.
 */
export async function fetchData<T = unknown>(
	input: string | URL | Request,
	options?: RequestOptions,
): Promise<T | undefined> {
	return fetcher(input, options);
}

/**
 * Do a request and get a result type with the data or error.
 */
export async function fetchResult<T = unknown>(
	input: string | URL | Request,
	options?: RequestOptions,
): Promise<Result<T | undefined, ResponseError | ValidationError | Error>> {
	try {
		const response = await fetcher<T>(input, options);
		return success(response);
	} catch (error) {
		if (error instanceof Error) {
			return failure(error);
		}
	}
	return failure(new Error('Unknown error'));
}
