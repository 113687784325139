/**
 * IconButton
 */

import React, {
	type AnchorHTMLAttributes,
	type ButtonHTMLAttributes,
} from 'react';

import Count, { type Props as CountProps } from 'components/Count';
import type { IconColor, IconDirection, IconName } from 'components/Icon';
import Icon from 'components/Icon';
import LinkOrButton from 'components/LinkOrButton';
import { cn } from 'utils/classNames';

interface BaseProps {
	/** Small floating badge */
	badge?: string | number;

	/** Extra props for the small floating badge */
	badgeProps?: Partial<CountProps>;

	/** Additional class names */
	className?: string;

	/** If the button is disabled, or without href for links */
	disabled?: boolean;

	/** Hover color classes, defaults to a light gray */
	hoverClasses?: string;

	/** Icon name */
	icon: IconName;

	/** Custom icon color */
	iconColor?: IconColor;

	/** Icon direction/rotation */
	iconDirection?: IconDirection;

	/** Disable and show a loading spinner */
	isLoading?: boolean;

	/** Button size */
	size?: 'default' | 'small';

	/** Text label */
	text: string;

	/** Classes for the text label, when visible */
	textClassName?: string;

	/** If the text should be visible */
	visibleText?: boolean;
}

type AnchorAttrs = AnchorHTMLAttributes<HTMLAnchorElement>;
type ButtonAttrs = ButtonHTMLAttributes<HTMLButtonElement>;

interface AnchorProps extends BaseProps, Omit<AnchorAttrs, 'children'> {
	type?: never;

	/** Link URL, results in an anchor instead of a button */
	href: string;
}

interface ButtonProps extends BaseProps, Omit<ButtonAttrs, 'children'> {
	href?: never;

	/** Button click handler */
	onClick: ButtonAttrs['onClick'];
}

type Props = AnchorProps | ButtonProps;

/** Icon button with a gray circle on hover. */
const IconButton = React.forwardRef<
	HTMLAnchorElement | HTMLButtonElement,
	Props
>(
	(
		{
			badge,
			badgeProps,
			className,
			disabled,
			hoverClasses = '[@media(hover:hover)]:hover:bg-greyLighter',
			href,
			icon,
			iconColor,
			iconDirection,
			isLoading,
			onClick,
			size = 'default',
			text,
			textClassName = '',
			type = 'button',
			visibleText = false,
			...attrs
		},
		ref,
	) => (
		<LinkOrButton
			{...attrs}
			ref={ref}
			aria-label={visibleText ? undefined : text}
			className={cn(
				'inline-flex items-center rounded-full',
				(disabled || isLoading) && 'opacity-50',
				!disabled && hoverClasses,
				visibleText && 'px-1',
				className,
			)}
			disabled={disabled || isLoading}
			href={href}
			onClick={onClick}
			type={type}
		>
			<span
				className={cn(
					'relative inline-block',
					size === 'default' && 'p-3',
					size === 'small' && 'p-2',
				)}
			>
				<Icon
					align="top"
					color={iconColor}
					direction={iconDirection}
					name={isLoading ? 'spinner' : icon}
					className={isLoading ? 'animate-spinFast' : undefined}
				/>
				<Count
					amount={badge}
					variant="badge"
					size="regular"
					color="green"
					margin="none"
					className={cn('absolute top-0', visibleText ? 'right-1' : 'right-0')}
					aria-hidden="true"
					{...badgeProps}
				/>
			</span>
			{/* A hidden sr-only text is slightly better than aria-label for
				accessibility but causes a weird outline issue in Safari. */}
			{visibleText && (
				<span className={cn('-ml-1 mr-3', textClassName)}>{text}</span>
			)}
		</LinkOrButton>
	),
);
IconButton.displayName = 'IconButton';

export default IconButton;
