import React, { type ReactNode, useEffect, useRef, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';

import IconButton from 'components/IconButton';
import { useIsomorphicLayoutEffect, useWindowSize } from 'hooks';
import { type Breakpoint, breakpoints } from 'styles/media';
import { cn, cnm } from 'utils/classNames';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import 'keen-slider/keen-slider.min.css';

interface Props {
	buttonLeftClassName?: string;
	buttonRightClassName?: string;
	className?: string;
	hasOuterGutter?: boolean;
	items: ReactNode[];
	onNext?: (nextIndex: number) => void;
	sizeClasses?: string;
	sliderClassName?: string;
}

const SLIDES_PER_VIEW = {
	'w-full': 1,
	'w-11/12': 12 / 11,
	'w-5/6': 6 / 5,
	'w-4/5': 5 / 4,
	'w-2/3': 3 / 2,
	'w-5/8': 8 / 5,
	'w-1/2': 2,
	'w-1/3': 3,
	'w-1/4': 4,
	'w-1/5': 5,
	'w-1/6': 6,
	// Widths for showing X items plus a sliver of another. They're primes so
	// no meaningful fraction class can be added.
	'w-[23%]': 100 / 23,
	'w-[29%]': 100 / 29,
} as const;

function getBreakPointStyles(sizeClasses: string) {
	const sliderBreakpoints = sizeClasses.split(' ');

	return Object.fromEntries(
		sliderBreakpoints.map((breakpoint: string) => {
			const breakpointParts = breakpoint.split('w-');
			const namePart = breakpointParts.shift()?.replace(':', '');
			const breakpointName: Breakpoint =
				namePart && is.keyOf(breakpoints, namePart) ? namePart : 'xs';
			const tailwindWidth = `w-${breakpointParts.pop()}`;
			const perView = is.keyOf(SLIDES_PER_VIEW, tailwindWidth)
				? SLIDES_PER_VIEW[tailwindWidth]
				: 4;
			const breakPointPx = breakpoints[breakpointName];

			return [
				`(min-width: ${breakPointPx}px)`,
				{
					slides: {
						perView,
						spacing: is.oneOf(breakpointName, 'xs', 'sm') ? 16 : 24,
					},
				},
			];
		}),
	);
}

// Consider https://swiperjs.com/react when React 19 is released, or look at
// something like https://github.com/karanokara/react-scroll-snap-anime-slider
// and implement a custom one based on scroll snapping.
export default function Slider({
	buttonLeftClassName,
	buttonRightClassName,
	className,
	hasOuterGutter,
	items,
	onNext,
	sizeClasses = 'w-2/3 sm:w-1/3 md:w-1/4 lg:w-1/5',
	sliderClassName,
}: Props) {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [sliderWidth, setSliderWidth] = useState(0);
	const [scrollWidth, setScrollWidth] = useState(0);
	const [scrollPosition, setScrollPosition] = useState(0);
	const sliderRef = useRef<HTMLUListElement | null>(null);
	const { t } = useI18n();
	const slideCount = items.length;

	// To avoid hydration errors, set state with an effect instead of just
	// keeping a plain variable which would otherwise work as well.
	const { width: windowWidth } = useWindowSize(0);
	const [shouldUseJs, setShouldUseJs] = useState(false);
	useEffect(() => {
		setShouldUseJs(windowWidth >= breakpoints.md);
	}, [windowWidth]);

	// Update page tracker indicator on scroll.
	const handleScroll = (e: Event) => {
		setScrollPosition((e.target as HTMLElement).scrollLeft);
	};
	useIsomorphicLayoutEffect(() => {
		const sliderElement = sliderRef.current;
		if (sliderElement) {
			setScrollWidth(sliderElement.scrollWidth);
			setSliderWidth(sliderElement.offsetWidth);
			sliderElement.addEventListener('scroll', handleScroll);
		}
		return () => {
			sliderElement?.removeEventListener('scroll', handleScroll);
		};
	}, [sliderRef]);

	const [isJsSlider, setIsJsSlider] = useState(false);
	const [keenRef, keenInstanceRef] = useKeenSlider({
		mode: 'free-snap',
		breakpoints: getBreakPointStyles(sizeClasses),
		disabled: !shouldUseJs,
		slideChanged(sliderProps) {
			setCurrentSlide(sliderProps.track.details.rel);
		},
		created() {
			setIsJsSlider(true);
		},
		destroyed() {
			setIsJsSlider(false);
		},
	});

	useEffect(() => {
		keenInstanceRef.current?.update();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slideCount]);

	if (!is.arrayWithLength(items)) {
		return null;
	}

	const currentIndex = keenInstanceRef.current?.track?.details?.abs ?? 0;
	const slidesOption = keenInstanceRef.current?.options?.slides;
	const slidesPerView =
		typeof slidesOption === 'object' &&
		is.number(slidesOption?.perView) &&
		slidesOption.perView
			? slidesOption.perView
			: 1;

	let trackerWidth = (sliderWidth / scrollWidth) * 100;
	let trackerPos = trackerWidth * (scrollPosition / sliderWidth);
	if (shouldUseJs) {
		trackerWidth = (slidesPerView / slideCount) * 100;
		trackerPos = trackerWidth * (currentIndex / slidesPerView);
	}
	const hasTracker = shouldUseJs && slidesPerView < slideCount;
	const slideBaseClasses = cn(
		'flex-shrink-0 flex-grow-0 py-px',
		shouldUseJs && 'keen-slider__slide',
	);

	return (
		<div
			className={cnm(
				'group/slider relative [&>button]:focus-within:opacity-100',
				hasOuterGutter && 'max-md:px-4',
				className,
			)}
		>
			<ul
				ref={shouldUseJs ? keenRef : sliderRef}
				className={cn(
					'flex overflow-x-auto',
					shouldUseJs && 'keen-slider',
					!isJsSlider && '-mx-4 pl-4 md:-mx-6 md:pl-6',
					// Bottom padding to give the scrollbar some space.
					!isJsSlider && 'pb-2',
					hasOuterGutter && 'md:px-6',
					sliderClassName,
				)}
			>
				{items.map((item, index) => (
					<li
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						className={cn(
							slideBaseClasses,
							sizeClasses,
							!isJsSlider &&
								'pl-4 first:-ml-4 last:mr-4 md:pl-6 md:first:-ml-6 md:last:mr-6',
						)}
					>
						{item}
					</li>
				))}
				{hasOuterGutter && (
					// keen-slider doesn't handle padding on the slider container or
					// margin on first/last item and will thus cut off the last item when
					// the slider has padding for the outer gutter. The only solution is
					// to add an empty dummy slide that will take the same space as the
					// ones with content. Ugly and stupid.
					<li
						role="presentation"
						className={cn(slideBaseClasses, '!w-4 !min-w-0 !max-w-none')}
					/>
				)}
			</ul>
			{isJsSlider && keenInstanceRef.current && (
				<>
					<IconButton
						size="small"
						icon="arrow"
						iconColor="white"
						iconDirection="left"
						text={t('slider_prev_button')}
						hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDark"
						className={cnm(
							'group-hover/slider:opacity-100',
							'border-4 border-white bg-julaRed',
							'absolute -left-5 top-1/2 -translate-y-1/2 xl:-left-6 full:-translate-x-full',
							'md:opacity-0 full:opacity-100',
							hasTracker && '-mt-4',
							currentSlide === 0 && 'hidden',
							buttonLeftClassName,
						)}
						onClick={() => {
							const desiredIndex = currentIndex - slidesPerView;
							const nextIndex = Math.max(desiredIndex, 0);
							keenInstanceRef.current?.moveToIdx(nextIndex);
						}}
					/>
					<IconButton
						size="small"
						icon="arrow"
						iconColor="white"
						text={t('slider_next_button')}
						hoverClasses="[@media(hover:hover)]:hover:bg-julaRedDark"
						className={cnm(
							'group-hover/slider:opacity-100',
							'border-4 border-white bg-julaRed',
							'absolute -right-5 top-1/2 -translate-y-1/2 xl:-right-6 full:translate-x-full',
							'md:opacity-0 full:opacity-100',
							hasTracker && '-mt-4',
							currentSlide ===
								keenInstanceRef.current?.track?.details?.maxIdx && 'hidden',
							buttonRightClassName,
						)}
						onClick={() => {
							const desiredIndex = currentIndex + slidesPerView;
							const maxIndex =
								keenInstanceRef.current?.track?.details?.maxIdx ?? 0;
							const nextIndex = Math.min(desiredIndex, maxIndex);
							keenInstanceRef.current?.moveToIdx(nextIndex);
							if (is.func(onNext)) {
								onNext(nextIndex);
							}
						}}
					/>
				</>
			)}
			{hasTracker && (
				<div className="relative mx-auto mt-8 h-1 w-full max-w-[250px] overflow-hidden rounded-md bg-greyLight">
					<div
						style={{ transform: `translateX(${trackerPos}%)` }}
						className="w-full transition-transform"
					>
						<div
							style={{ width: `${trackerWidth}%` }}
							className="h-1 rounded-md bg-greyDark"
						/>
					</div>
				</div>
			)}
		</div>
	);
}
Slider.displayName = 'Slider';
