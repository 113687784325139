import React, { useState } from 'react';

import Button from 'components/Button';
import Count from 'components/Count';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { Response } from 'models/api';
import { cn } from 'utils/classNames';
import { formatElapsedTime } from 'utils/format';
import { ignorePromiseRejection, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

type FeedbackType = 'up' | 'down' | 'bad';

async function sendFeedback(url: string, type: FeedbackType): Promise<boolean> {
	try {
		await fetch(`${url}?type=${type}`);
		return true;
	} catch {
		// Continue to default return
	}

	return false;
}

interface Props {
	author: string;
	date: string;
	extract: string;
	feedbackUrl: string;
	responses: Response[];
	votesUp: number;
}

/** A single product question. */
export default function ProductQuestion({
	author,
	date,
	extract,
	feedbackUrl,
	responses,
	votesUp,
}: Props) {
	const { t } = useI18n();
	const [isUpvoting, setIsUpvoting] = useState(false);
	const [isReporting, setIsReporting] = useState(false);
	const [hasLeftFeedback, setHasLeftFeedback] = useState(false);

	const feedbackAction = async (
		activeState: boolean,
		activeStateSetter: (newState: boolean) => void,
		feedback: FeedbackType,
	) => {
		if (activeState) {
			return;
		}
		activeStateSetter(true);
		const wasSent = await sendFeedback(feedbackUrl, feedback);
		activeStateSetter(false);
		if (wasSent) {
			setHasLeftFeedback(true);
		}
	};

	const handleUpvoteClick = () => {
		ignorePromiseRejection(feedbackAction(isUpvoting, setIsUpvoting, 'up'));
	};

	const handleReportClick = () => {
		ignorePromiseRejection(feedbackAction(isReporting, setIsReporting, 'bad'));
	};

	const isLeavingFeedback = isUpvoting || isReporting;

	return (
		<article className="py-8" aria-label={`${author}`}>
			<Text as="p" styleAs="h6">
				{author}
			</Text>
			<Text as="pSmall">{formatElapsedTime(date)}</Text>

			<Text as="p" className="my-4">
				{extract}
			</Text>

			{is.arrayWithLength(responses) && (
				<div className="mt-6 space-y-4 rounded-lg bg-greyLighter p-4">
					{responses.map((response) => (
						<div key={response.answer}>
							<Text as="h4" styleAs="h6">
								<Icon
									name="julaJ"
									size={16}
									color="white"
									className="mr-2"
									backgroundColor="julaRed"
									hasBackgroundPadding
								/>
								{response.name}
							</Text>
							<Text as="pSmall" className="ml-8">
								{response.answer}
							</Text>
						</div>
					))}
				</div>
			)}

			<div className="mt-6 flex min-h-[2rem] items-center">
				{hasLeftFeedback && (
					<span className="text-sm">
						{t('product_details_review_feedback_done_text')}
					</span>
				)}
				{!hasLeftFeedback && (
					<>
						<Icon
							name="thumbDown"
							color="black"
							size={16}
							className={cn('rotate-180', isUpvoting && 'hidden')}
						/>
						<Icon
							name="spinner"
							color="black"
							size={16}
							className={cn('animate-spinFast', !isUpvoting && 'hidden')}
						/>
						<Count
							amount={votesUp}
							size="regular"
							margin="none"
							color="inherit"
							className="ml-1.5 font-bold"
						/>
						<Button
							variant="text"
							size="medium"
							className="ml-2"
							hasExpandedHitArea
							onClick={handleUpvoteClick}
							disabled={isLeavingFeedback}
						>
							{t('product_details_review_upvote_button')}
						</Button>

						<Button
							variant="text"
							size="medium"
							hasExpandedHitArea
							className="ml-6"
							onClick={handleReportClick}
							disabled={isLeavingFeedback}
						>
							<span>{t('product_details_review_report_button')}</span>
							<Icon
								name="spinner"
								color="grey"
								size={16}
								className={cn(
									'relative top-px ml-1 animate-spinFast',
									!isReporting && 'hidden',
								)}
							/>
						</Button>
					</>
				)}
			</div>
		</article>
	);
}
ProductQuestion.displayName = 'ProductQuestion';
