/**
 * Cart
 */

import React from 'react';
import { Form } from 'react-final-form';

import ActionButton from 'components/ActionButton';
import Button from 'components/Button';
import DisclosureButton from 'components/DisclosureButton';
import { TextInput } from 'components/FinalForm';
import { composeValidators, pattern, required } from 'components/GenericForm';
import Icon from 'components/Icon';
import InfoBox from 'components/InfoBox';
import Text from 'components/Text';
import { useFeatureToggle } from 'contexts';
import type { CartError, DiscountCode, GiftCard, Summary } from 'models/api';
import { findTheErrorTitle, getTestDataAttrFrom } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import PriceSummaries from './PriceSummaries';

interface Props {
	/** All bonus discount codes added to the cart, used by the summary component */
	bonusDiscounts: DiscountCode[] | undefined;

	/** Discount Object */
	campaignDiscount?: DiscountCode;

	checkoutButtonHref: string;

	/** Delete discount code function */
	deleteDiscountCode: (code: string) => void;

	/** Option to disable the button */
	disableButton?: boolean;

	/** DiscountCombinationError */
	discountCombinationError?: boolean;

	/** DiscountCountError */
	discountCountError?: boolean;

	/** List of Errors */
	errorList: CartError[] | undefined;

	/** Freight Object */
	freightDiscount?: DiscountCode;

	/** All gift cards added to the cart, used by the summary component */
	giftCards: GiftCard[] | undefined;

	/** Heading text of the cart summary component */
	heading?: string;
	/** callback function to submit the discount code form */
	onDiscountCodeSubmit: (
		values: Record<string, number>,
	) => Promise<{ discountCode: string } | { FORM_ERROR: string } | undefined>;
	/** Total savings, if any, used by the summary component */
	savingTotalSumSummary: Summary | undefined;
	/** Summaries for the cart, used by the summary component */
	summaries: Summary[] | undefined;
}

function Discount({
	code,
	deleteDiscountCode,
}: {
	code: DiscountCode | undefined;
	deleteDiscountCode: (code: string) => void;
}) {
	const { t } = useI18n();
	const checkNumber = code?.checkNumber;

	if (!checkNumber) {
		return null;
	}

	return (
		<div>
			<div className="mb-4 flex h-14 w-full items-center justify-between rounded-md border border-cta">
				<div className="flex items-center">
					<Text as="p" className="ml-4 font-bold text-greyDarker">
						{checkNumber}
					</Text>
					<Text as="p" className="ml-2 text-greyDarker">
						<span>{t('cart_coupon_active_label')}</span>
					</Text>
				</div>
				<Button
					variant="text"
					className="mr-4"
					onClick={() => deleteDiscountCode(checkNumber)}
				>
					{t('cart_remove_from_cart_button')}
				</Button>
			</div>
		</div>
	);
}
Discount.displayName = 'CartSummary_Discount';

/** The cart summary component used in the cart page. */
export default function CartSummary({
	bonusDiscounts,
	campaignDiscount,
	checkoutButtonHref,
	deleteDiscountCode,
	disableButton = false,
	discountCombinationError,
	discountCountError,
	errorList,
	freightDiscount,
	giftCards,
	heading,
	onDiscountCodeSubmit,
	savingTotalSumSummary,
	summaries,
}: Props) {
	const { t } = useI18n();

	const { onlineCommerceEnabled } = useFeatureToggle();

	const validation = [
		required(t('FieldIsRequired')),
		pattern({
			patternString: '^(.){4,30}$',
			required: true,
			errorMessage: t('DiscountCodeInvalidLength'),
		}),
	];
	return (
		<div className="-mt-8 flex flex-col rounded-lg md:mt-0 md:border md:border-grey md:px-6 md:pb-6 md:pt-6">
			<Text as="h2" className="mt-6 md:mb-4 md:mt-0">
				{heading}
			</Text>
			<div>
				<DisclosureButton label={t('cart_summary_gift_card_button')}>
					<>
						<Form
							onSubmit={onDiscountCodeSubmit}
							render={({
								form,
								handleSubmit,
								hasSubmitErrors,
								submitSucceeded,
								submitting,
							}) => {
								if (submitSucceeded) {
									form.restart();
								}
								return (
									<form onSubmit={handleSubmit} className="mt-4">
										<div className="flex items-start">
											<TextInput
												name="discountCode"
												label={t('cart_coupon_input_label')}
												validate={composeValidators(...validation)}
												id="discountCode"
												className="mr-4"
												data-cy={getTestDataAttrFrom('discount-code-input')}
											/>

											<ActionButton
												type="submit"
												variant="cta"
												size="large"
												customState={
													submitting
														? 'loading'
														: hasSubmitErrors
															? 'failure'
															: 'idle'
												}
												disabled={!onlineCommerceEnabled}
												data-cy={getTestDataAttrFrom('discount-code-button')}
											>
												{t('cart_summary_apply_gift_card_button')}
											</ActionButton>
										</div>
									</form>
								);
							}}
						/>
						{discountCombinationError && errorList && (
							<div className="mt-4">
								<InfoBox
									icon="info"
									variant="error"
									message={findTheErrorTitle(
										'DiscountCombinationInvalid',
										errorList,
									)}
								/>
							</div>
						)}
						{discountCountError && errorList && (
							<div className="mt-4">
								<InfoBox
									icon="info"
									variant="information"
									message={findTheErrorTitle('DiscountCountError', errorList)}
								/>
							</div>
						)}
					</>
				</DisclosureButton>
			</div>

			<div className="mt-4 text-greyDarker">
				<Discount
					code={campaignDiscount}
					deleteDiscountCode={deleteDiscountCode}
				/>
				<Discount
					code={freightDiscount}
					deleteDiscountCode={deleteDiscountCode}
				/>
				<PriceSummaries
					summaries={summaries}
					bonusDiscounts={bonusDiscounts}
					savingTotalSumSummary={savingTotalSumSummary}
					giftCards={giftCards}
				/>
			</div>

			<Button
				href={checkoutButtonHref}
				variant="cta"
				displayWidth="full"
				className="mt-6"
				disabled={disableButton}
				data-cy={getTestDataAttrFrom('cart-checkout-button')}
			>
				{t('cart_checkout_button')}
			</Button>

			<div className="mt-6 flex md:mt-4">
				<div className="last-child:mr-0 mr-4 flex items-center justify-end">
					<Icon name="check" className="text-cta" aria-hidden />
					<Text as="pSmall" className="ml-1">
						{t('cart_usp_1_text')}
					</Text>
				</div>
				<div className="last-child:mr-0 mr-4 flex items-center justify-end">
					<Icon name="check" className="text-cta" aria-hidden />
					<Text as="pSmall" className="ml-1">
						{t('cart_usp_2_text')}
					</Text>
				</div>
			</div>
		</div>
	);
}
CartSummary.displayName = 'CartSummary';
