import { parse as parseCookie } from 'cookie';

import { publicRuntimeConfig } from 'config';
import type { BusinessLogicError } from 'models/api';
import { ensureFreshToken } from 'utils/fetch';
import {
	formatValidationError,
	generateConsentHeader,
	parseConsentString,
	sendGlobalEvent,
} from 'utils/helpers';
import { log } from 'utils/log';

export const API_URL: string =
	(process.env.NODE_ENV === 'development'
		? process.env.NEXT_PUBLIC_LOCAL_API_URL
		: publicRuntimeConfig?.NEXT_PUBLIC_DIGITAL_PLATFORM_API_URL) ?? '/';

export interface ExtendedResponse<T> {
	responseData: T;
	status: number;
}
export interface ExtendedResponseError {
	errorText: string;
	headers: Headers;
	status: number;
}
export interface FormattedValidationErrors {
	businessLogicErrors?: BusinessLogicError[];
	fieldValidationErrors?: Record<string, string[]>;
	status?: number;
}
/**  @deprecated Use new fetchData and fetchResult  instead */
async function fetchData<T = void>(
	url: string,
	config: RequestInit = {},
	extendedResponse = false,
	retries = 0,
): Promise<T | undefined> {
	// Copy to avoid modifying the passed object.
	const options: RequestInit = { ...config };
	const headers: any = {
		'pragma': 'no-cache',
		'cache-control': 'no-cache',
		'Content-Type': 'application/json',

		'CF-Access-Client-Id': publicRuntimeConfig?.NEXT_PUBLIC_CF_ACCESS_CLIENT_ID,
		'CF-Access-Client-Secret':
			publicRuntimeConfig?.NEXT_PUBLIC_CF_ACCESS_CLIENT_SECRET,
	};
	// Detta ser skumt ut, men 'multipart/form-data' måste sättas av fetch metoden
	// själv för att boundry, som är en del av headern, ska sättas korrekt, därför
	// sätts application/json som default, om multipart/form-data headern inte är
	// del av config då vi istället tar bort allt och låter fetch sköta headers själv.
	if (options?.headers?.['Content-Type'] === 'multipart/form-data') {
		delete options.headers['Content-Type'];
		delete headers['Content-Type'];
	}
	options.headers = options.headers
		? { ...options.headers, ...headers }
		: headers;

	if (typeof document !== 'undefined') {
		options.headers = {
			...options.headers,
			...generateConsentHeader(
				parseConsentString(parseCookie(document.cookie).OptanonConsent),
			),
		};
	}

	await ensureFreshToken();

	return new Promise((resolve, reject) => {
		fetch(url, { ...options, cache: 'no-store', credentials: 'include' })
			.then(async (response) => {
				if (!response.ok) {
					// If it looks like we have an authorization error we send an event to ask the authentication machine to check status.
					if (response.status === 401) {
						log.warning(response);
						if (retries < 2) {
							sendGlobalEvent('refresh-token');
							return fetchData(url, options, extendedResponse, retries + 1);
						}
						sendGlobalEvent('logout');
					}
					if (response.status === 400) {
						log.warning(response);

						try {
							const data = await response.json();

							return reject({
								...formatValidationError(data),
								status: response.status,
							});
						} catch {
							return reject();
						}
					}
					return extendedResponse
						? reject({
								status: response.status,
								errorText: response.statusText,
								headers: response.headers,
							} as ExtendedResponseError)
						: reject(new Error(`${response.status}: ${response.statusText} `));
				}
				return response
					.json()
					.then((data) =>
						extendedResponse
							? { ...data, responseData: data, status: response.status }
							: data,
					)
					.catch((error) => {
						if (response.status === 204) {
							// @ts-expect-error: it's all a mess anyway
							return resolve({ status: response.status });
						}
						log.error(error);
						if (!response.ok) {
							return extendedResponse
								? reject({
										status: response.status,
										errorText: response.statusText,
										headers: response.headers,
									} as ExtendedResponseError)
								: reject(
										new Error(`${response.status}: ${response.statusText} `),
									);
						}
						return extendedResponse
							? reject({
									status: response.status,
									errorText: response.statusText,
								})
							: reject(new Error(error));
					});
			})
			.then((data) => {
				if (!data) {
					return resolve(undefined);
				}
				if (data.Success) {
					return resolve(data.Data);
				}
				if (data.SystemMessages) {
					log.error(data.SystemMessages);

					const errorMsg = data.SystemMessages.map((msg: any) => msg.Message);
					return reject(new Error(errorMsg));
				}
				if (data.Message) {
					log.error(data.Message);

					const error = JSON.stringify(data.ModelState);
					return reject(new Error(error));
				}
				return resolve(data);
			})
			.catch((error) => {
				log.error(error);
				return reject(error);
			});
	});
}

export default fetchData;
