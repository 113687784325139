import { useCallback, useEffect } from 'react';

import { useChatContext, useGlobalMachinesContext } from 'contexts';
import { useChatAvailability, useGlobalEvent, useGlobalLinks } from 'hooks';
import { useI18n } from 'utils/i18n';

import { useLazyGlobalValue } from './useLazyGlobalValue';

export function useChat(isActive = false) {
	const { t } = useI18n();
	const { globalPopoverService } = useGlobalMachinesContext();
	const zE = useLazyGlobalValue('zE');
	const { customerChat } = useGlobalLinks();
	const { isAvailable, isLoading, isOpen, zendeskJwt } =
		useChatAvailability(isActive);
	const {
		hasOpenedMessenger,
		messageCount,
		messengerIsOpen,
		setHasOpenedMessenger,
		setMessageCount,
		setMessengerIsOpen,
	} = useChatContext();

	useEffect(() => {
		if (zendeskJwt && zE) {
			zE('messenger', 'loginUser', (callback: (jwt: string) => void) => {
				callback(zendeskJwt);
			});
		}
	}, [zendeskJwt, zE]);

	useGlobalEvent('set-show-zendesk-chat-button', (e) => {
		setMessengerIsOpen(e.detail);
	});

	useGlobalEvent('set-zendesk-chat-message-count', (e) => {
		setMessageCount(e.detail);
	});

	const openChat = useCallback(() => {
		if (zE) {
			zE('messenger', 'open');
			setHasOpenedMessenger();
		}
	}, [setHasOpenedMessenger, zE]);

	const openGlobalPopover = useCallback(() => {
		globalPopoverService.send({
			type: 'OPEN',
			target: customerChat || '',
			heading: t('customer_service_chat_popover_heading'),
		});
	}, [customerChat, globalPopoverService, t]);

	return {
		chatAvailabilityIsLoading: isLoading,
		/** Zendesk chat system availability */
		chatIsAvailable: isAvailable,
		/** Zendesk chat system open status */
		chatIsOpen: isOpen,
		hasOpenedMessenger,
		messageCount,
		/** Zendesk client chat window */
		messengerIsOpen,
		openChatOrPopover: hasOpenedMessenger ? openChat : openGlobalPopover,
		setHasOpenedMessenger,
		zE,
		/** Zendesk script/globals not available yet */
		zendeskIsLoading: Boolean(!zE),
	};
}
