/**
 * Status
 */

import React, { type ReactNode } from 'react';

import Icon from 'components/Icon';
import Text, { Tags } from 'components/Text';
import { cn, cnm } from 'utils/classNames';

type StatusColor = 'green' | 'orange' | 'red';

export type TextSize = 'base' | 'small' | 'xSmall';
const textSizeMap: Record<TextSize, Tags> = {
	base: 'p',
	small: 'pSmall',
	xSmall: 'pXSmall',
};

interface DotProps {
	className?: string;
	color: StatusColor;
}

function Dot({ className, color }: DotProps) {
	return (
		<span
			className={cn(
				'h-2 w-2 rounded-full',
				color === 'green' && 'bg-success',
				color === 'orange' && 'bg-electricsLightning',
				color === 'red' && 'bg-error',
				className,
			)}
		/>
	);
}
Dot.displayName = 'Status_Dot';

type Props = IconStatusProps | DotStatusProps;

interface DotStatusProps extends BaseProps {
	/** Sets the color of the component */
	color: StatusColor;
	/** What to use as a status symbol */
	statusSymbol: 'dot';
}

interface IconStatusProps extends BaseProps {
	/** Sets the color of the component */
	color: 'green' | 'red';
	/** What to use as a status symbol */
	statusSymbol?: 'icon';
}

interface BaseProps {
	/** Should the component have a rounded background? */
	asPill?: boolean;
	/** Classes for the outer span */
	className?: string;
	/** What to use as a status symbol */
	statusSymbol?: 'icon' | 'dot';
	/** Pass optional classnames for the status symbol */
	statusSymbolClassName?: string;
	/** Text content */
	text: ReactNode;
	/** Text size */
	textSize?: 'base' | 'small' | 'xSmall';
}

/** Status component that displays a colored dot or a status icon */
export default function Status({
	asPill = false,
	className,
	color,
	statusSymbol = 'icon',
	statusSymbolClassName,
	text,
	textSize = 'base',
}: Props) {
	return (
		<Text
			as="div"
			styleAs={textSizeMap[textSize]}
			className={cnm(
				'inline-flex items-start align-top',
				asPill && 'rounded-full bg-greyLighter px-4 py-2',
				className,
			)}
		>
			{statusSymbol === 'icon' && (
				<Icon
					size={16}
					name={color === 'green' ? 'check' : 'closeOutline'}
					color={color === 'green' ? 'white' : 'julaRed'}
					backgroundColor={color === 'green' ? 'cta' : 'white'}
					className={cnm(
						'mr-1',
						'inline-block',
						'shrink-0',
						textSize === 'base' && 'mr-2 mt-0.5',
						textSize === 'small' && 'mr-1 mt-0.5',
						textSize === 'xSmall' && 'mr-1',
						statusSymbolClassName,
					)}
				/>
			)}
			{statusSymbol === 'dot' && (
				<Dot
					className={cnm(
						'mr-1',
						'inline-block',
						'shrink-0',
						textSize === 'base' && 'mr-2 mt-2',
						textSize === 'small' && 'mr-1 mt-1.5',
						textSize === 'xSmall' && 'mr-1 mt-1',
						statusSymbolClassName,
					)}
					color={color}
				/>
			)}
			{text}
		</Text>
	);
}

Status.displayName = 'Status';
