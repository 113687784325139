/**
 * Chip
 */

import React, {
	type AnchorHTMLAttributes,
	type ButtonHTMLAttributes,
} from 'react';

import Count from 'components/Count';
import Icon from 'components/Icon';
import LinkOrButton from 'components/LinkOrButton';
import { cn } from 'utils/classNames';
import { is } from 'utils/helpers';

export type ChipColor = 'grey' | 'red' | 'white';

interface BaseProps {
	/** Render a number next to the text */
	amount?: number | string;

	/** If a border should be set */
	border?: boolean;

	/** Additional class names */
	className?: string;

	/** Color variant */
	color: ChipColor;

	/** Disable and fade out */
	disabled?: boolean;

	/** Render chip as a link */
	href?: string;

	/** Render a cross icon next to the text */
	isRemovable?: boolean;

	/** Button text */
	text: string;
}

type AnchorAttrs = AnchorHTMLAttributes<HTMLAnchorElement>;
type ButtonAttrs = ButtonHTMLAttributes<HTMLButtonElement>;

interface AnchorProps
	extends BaseProps,
		Omit<AnchorAttrs, 'children' | 'color'> {
	type?: never;

	href: string;

	/** Target when rendering a link */
	target?: string;
}

interface ButtonProps
	extends BaseProps,
		Omit<ButtonAttrs, 'children' | 'color'> {
	href?: never;

	/** Button click handler */
	onClick: ButtonAttrs['onClick'];

	target?: never;
}

type Props = AnchorProps | ButtonProps;

/** Rounded button, optionally as a link */
const Chip = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>(
	(
		{
			amount,
			border = true,
			className,
			color,
			disabled,
			href,
			isRemovable = false,
			onClick,
			target,
			text,
			...attrs
		},
		ref,
	) => (
		<LinkOrButton
			{...attrs}
			ref={ref}
			disabled={disabled}
			href={href}
			type="button"
			target={target}
			onClick={onClick}
			className={cn(
				className,
				'inline-flex items-center rounded-3xl',
				'min-h-[3rem] px-5 py-2 sm:min-h-[2.5rem] sm:px-4',
				'font-standard text-base',

				border && 'border',
				disabled && 'cursor-default opacity-50',

				color === 'grey' && [
					'bg-greyLighter text-greyDarker',
					border && 'border-greyLighter',
					!disabled && [
						'hover:bg-greyLight',
						border && 'hover:border-greyLight',
					],
				],
				color === 'red' && [
					'bg-julaRed text-white',
					border && 'border-julaRed',
					!disabled && [
						'hover:bg-julaRedDark',
						border && 'hover:border-julaRedDark',
					],
				],
				color === 'white' && [
					'bg-white text-greyDarker',
					border && 'border-greyDark',
					!disabled &&
						'hover:border-greyDarker hover:ring-1 hover:ring-greyDarker',
				],
			)}
		>
			{isRemovable && (
				<Icon name="close" size={16} className="pointer-events-none mr-1" />
			)}
			{text}
			{is.renderable(amount) && (
				<Count
					variant="text"
					color={color === 'red' ? 'inherit' : 'auto'}
					amount={`(${amount})`}
				/>
			)}
		</LinkOrButton>
	),
);
Chip.displayName = 'Chip';

export default Chip;
