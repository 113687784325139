import React from 'react';

import { Skeleton, SkeletonItem } from 'components/Skeleton';
import { StockStatus } from 'components/Stock';
import { StoreIdName, useFeatureToggle } from 'contexts';
import { ItemStockResponse, StoreStock, WebStock } from 'models/api';
import { ProductCard } from 'models/productCard';
import {
	getFormattedStockAmount,
	getVariantPickerStoreStockStatus,
	getVariantPickerWebStockStatus,
	VariantPickerStoreStockStatusType,
	VariantPickerWebStockStatusType,
} from 'utils/business-logic/stock';
import { useI18n } from 'utils/i18n';

interface WebStockStatusProps {
	isSellable: boolean;
	isSellableOnWeb: boolean;
	webStock: WebStock | undefined;
}

function WebStockStatus({
	isSellable,
	isSellableOnWeb,
	webStock,
}: WebStockStatusProps) {
	const status = getVariantPickerWebStockStatus({
		isSellable,
		isSellableOnWeb,
		reStockWithinAcceptableTimespan: Boolean(
			webStock?.reStockWithinAcceptableTimespan,
		),
		availableStockLevel: webStock?.availableStockLevel || 0,
	});
	const { t } = useI18n();

	const getOnlineStockTranslation = (
		stockStatus: VariantPickerWebStockStatusType,
	) => {
		switch (stockStatus) {
			case 'InStock':
				return t('stock_online_amount_exact', {
					amount: getFormattedStockAmount(webStock?.availableStockLevel),
				});
			case 'ReStock':
				return t('stock_online_re_stock');
			case 'OutOfStock':
				return t('stock_online_out_of_stock_general');
			case 'InStoreOnly':
				return t('stock_online_store_only');
			default:
				return '';
		}
	};
	return (
		<StockStatus
			inStock={status === 'InStock'}
			text={getOnlineStockTranslation(status)}
		/>
	);
}
WebStockStatus.displayName = 'VariantPicker_StockInformation_WebStockStatus';

interface StoreStockStatusProps {
	selectedStore: StoreIdName | undefined;
	storeStock: StoreStock | undefined;
}
function StoreStockStatus({
	selectedStore,
	storeStock,
}: StoreStockStatusProps) {
	const { t } = useI18n();
	const currentStoreStock = storeStock?.stocks?.find(
		(stock) => stock.store.id === selectedStore?.id,
	);
	const status = getVariantPickerStoreStockStatus({
		storeSelected: Boolean(selectedStore?.name),
		inStock: Boolean(currentStoreStock?.inStock),
		productInStockAtStores: storeStock?.inStockCount || 0,
	});
	const getStoreStockTranslation = (
		stockStatus: VariantPickerStoreStockStatusType,
	) => {
		switch (stockStatus) {
			case 'Store-InStock':
				return t('stock_store_name_amount_exact', {
					store: selectedStore?.name,
					amount: getFormattedStockAmount(
						currentStoreStock?.availableStockLevel,
					),
				});
			case 'Store-OutOfStock':
				return t('stock_store_out_of_stock_general', {
					store: selectedStore?.name,
				});
			case 'NoStore-InStock':
			case 'NoStore-OutOfStock':
				return t('stock_stores_amount_exact', {
					amount: storeStock?.inStockCount,
				});
			default:
				return '';
		}
	};
	const inStock = status === 'Store-InStock' || status === 'NoStore-InStock';

	return (
		<StockStatus inStock={inStock} text={getStoreStockTranslation(status)} />
	);
}
StoreStockStatus.displayName =
	'VariantPicker_StockInformation_StoreStockStatus';

interface Props {
	isLoading: boolean;
	selectedStore: StoreIdName | undefined;
	stock: ItemStockResponse | undefined;
	variant: ProductCard;
}

export default function StockInformation({
	isLoading,
	selectedStore,
	stock,
	variant,
}: Props) {
	const { storesEnabled } = useFeatureToggle();
	return (
		<>
			{isLoading && (
				<Skeleton className="flex flex-col gap-y-1">
					<SkeletonItem height="1.281rem" width="10rem" />
					<SkeletonItem height="1.281rem" width="10rem" />
				</Skeleton>
			)}
			{!isLoading && (
				<>
					<WebStockStatus
						isSellable={variant.isSellable}
						isSellableOnWeb={variant.isSellableOnWeb}
						webStock={stock?.webStock}
					/>
					{storesEnabled && (
						<StoreStockStatus
							storeStock={stock?.storeStock}
							selectedStore={selectedStore}
						/>
					)}
				</>
			)}
		</>
	);
}
StockInformation.displayName = 'VariantPicker_StockInformation';
