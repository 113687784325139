import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import RichText from 'components/RichText';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import type { ValidationError } from 'errors';
import { useEffectOnce } from 'hooks';
import type { ExistingPlacement } from 'hooks/product-details';
import {
	CreateCustomizationRequest,
	CustomizationPlacement,
	Option,
	Placement,
} from 'models/api/ProductCustomization';
import { StoredFileResponse } from 'models/api/userFileStorage';
import { PrintOnClothes } from 'models/product';
import { ActionButtonState } from 'state-machines/ActionButton.machine';
import { pushToGTM } from 'utils/GoogleTagManager';
import { is } from 'utils/helpers';

import PrintPlacement from './PrintPlacement';
import ProductPrintPopover from './ProductPrintPopover';

interface Props {
	addPrintPlacementButtonState: ActionButtonState;
	addPrintPlacementErrors: ValidationError | undefined;
	className?: string;
	existingPrintPlacements: ExistingPlacement[] | undefined;
	fileUploadButtonState: ActionButtonState;
	onAddPrintPlacementClick: (newPrint: CreateCustomizationRequest) => void;
	onRemovePrintClick: (id: string) => void;
	onRemovePrintPlacementClick: (identifier: string) => void;
	onReusePrintClick: (id: string) => void;
	onSelectExistingPrintPlacementClick: (
		newPrint: CreateCustomizationRequest,
	) => void;
	onUpLoadPrint: (file: File) => void;
	placements: Placement[] | undefined;
	print: StoredFileResponse | undefined;
	printOnClothes: PrintOnClothes;
	printPlacements: CustomizationPlacement[] | undefined;
	printUploadErrors: string[] | undefined;
	productId: string;
	storedPrints: StoredFileResponse[] | undefined;
	total: string | undefined;
}

export function ProductPrint({
	addPrintPlacementButtonState,
	addPrintPlacementErrors: currentAddPrintPlacementErrors,
	className,
	existingPrintPlacements: existingPrintPlacementsProp,
	fileUploadButtonState,
	onAddPrintPlacementClick,
	onRemovePrintClick,
	onRemovePrintPlacementClick,
	onReusePrintClick,
	onSelectExistingPrintPlacementClick,
	onUpLoadPrint,
	placements,
	print,
	printOnClothes,
	printPlacements,
	printUploadErrors: currentPrintUploadErrors,
	productId,
	storedPrints,
	total,
}: Props) {
	const existingPrintPlacements = useMemo(
		() =>
			existingPrintPlacementsProp?.filter(
				(exitingPrint) =>
					!printPlacements?.some(
						(printPlacement) => printPlacement.id === exitingPrint.id,
					),
			),
		[existingPrintPlacementsProp, printPlacements],
	);

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [isFullyClosed, setIsFullyClosed] = useState(false);
	const [currentPageIndex, setCurrentPageIndex] = useState(
		is.arrayWithLength(existingPrintPlacements) ? 3 : 0,
	);

	const [selectedSize, setSelectedSize] = useState<Option>();
	const [selectedPlacement, setSelectedPlacement] = useState<Placement>();

	const [printUploadErrors, setPrintUploadErrors] = useState<string[]>();
	const [addPrintPlacementErrors, setAddPrintPlacementErrors] = useState<
		ValidationError | undefined
	>();

	const reset = useCallback(() => {
		setSelectedPlacement(undefined);
		setSelectedSize(undefined);
		onRemovePrintClick('');
		setPrintUploadErrors(undefined);
		setAddPrintPlacementErrors(undefined);
		setCurrentPageIndex(is.arrayWithLength(existingPrintPlacements) ? 3 : 0);
	}, [existingPrintPlacements, onRemovePrintClick]);

	// Sometimes the reset is run before the existingPrintPlacements array
	// is updated, leading to the currentPageIndex not being correct
	// when the popover is opened, add this extra use effect to deal with that
	useEffect(() => {
		if (isFullyClosed) {
			setCurrentPageIndex(is.arrayWithLength(existingPrintPlacements) ? 3 : 0);
		}
	}, [existingPrintPlacements, isFullyClosed]);

	useEffect(() => {
		setPrintUploadErrors(currentPrintUploadErrors);
	}, [currentPrintUploadErrors]);

	useEffect(() => {
		setAddPrintPlacementErrors(currentAddPrintPlacementErrors);
	}, [currentAddPrintPlacementErrors]);

	useEffect(() => {
		if (addPrintPlacementButtonState === 'success') {
			setIsPopoverOpen(false);
		}
	}, [addPrintPlacementButtonState]);

	useEffectOnce(() => {
		pushToGTM({
			type: 'view_promotion',
			payload: {
				id: 'profiling',
				name: `Profiling: Clothing ${productId}`,
			},
		});
	});

	const {
		addedPrintsLabel,
		addPrintDescription,
		addPrintHeading,
		addPrintPrimaryButton,
		addPrintSecondaryButton,
		placementDisabled,
		placementHeading,
		popoverCancel,
		popoverName,
		removePrintButton,
		reuseDescription,
		reuseHeading,
		reuseLabel,
		reusePrintButton,
		reuseSubmitButton,
		sizeHeading,
		totalPriceLabel,
		uploadDescription,
		uploadHeading,
		uploadPriceLabel,
		uploadPrintButton,
		uploadRemoveButton,
		uploadReuseButton,
		uploadReuseLabel,
		uploadSubmitButton,
		uploadWarning,
	} = printOnClothes;

	return (
		<div className={className}>
			<Text as="p" field={addPrintHeading} className="font-bold" />
			<RichText field={addPrintDescription} />

			{is.arrayWithLength(printPlacements) && (
				<>
					<Text
						as="p"
						text={addedPrintsLabel?.value}
						className="mt-8 font-bold"
					/>
					<ul className="mt-4 flex flex-col gap-2">
						{printPlacements.map((printPlacement) => (
							<PrintPlacement
								key={printPlacement.id}
								placement={printPlacement.title || ''}
								logotypeName={
									printPlacement.printImageFilename?.originalFileName
								}
								size={printPlacement.option?.text}
								price={printPlacement.option?.price?.priceIncVat}
								onClick={() => {
									onRemovePrintPlacementClick(printPlacement.id || '');
									reset();
								}}
								buttonText={removePrintButton?.value}
							/>
						))}
					</ul>
				</>
			)}
			<Button
				variant="text"
				onClick={() => {
					setIsPopoverOpen(true);
					setIsFullyClosed(false);
					pushToGTM({
						type: 'select_promotion',
						payload: {
							id: 'profiling',
							name: `Profiling: Clothing ${productId}`,
						},
					});
					pushToGTM({
						type: 'profiling_start',
						payload: {
							productId,
						},
					});
				}}
				aria-haspopup="dialog"
				className="group/product-print-button mt-6"
			>
				<Icon
					name="plus"
					color="black"
					direction="up"
					backgroundColor="greyLight"
					className="group-hover/product-print-button:bg-grey"
				/>
				{is.arrayWithLength(printPlacements)
					? addPrintPrimaryButton?.value
					: addPrintSecondaryButton?.value}
			</Button>
			{total && (
				<Text as="p" className="mt-4">
					{totalPriceLabel?.value} <span className="font-bold">{total}</span>
				</Text>
			)}
			<ProductPrintPopover
				existingPrintPlacements={existingPrintPlacements}
				onCreateNewPrintPlacementClick={() => {
					setCurrentPageIndex(0);
					setAddPrintPlacementErrors(undefined);
				}}
				onBackClick={() => {
					setCurrentPageIndex((current) => current - 1);
				}}
				onSelectExistingPrintPlacementClick={
					onSelectExistingPrintPlacementClick
				}
				isOpen={isPopoverOpen}
				currentPageIndex={currentPageIndex}
				onAddPrintPlacementClick={() => {
					if (selectedPlacement && selectedSize && print?.fileName) {
						onAddPrintPlacementClick({
							placementId: selectedPlacement.id,
							printImageFilename: print.fileName,
							optionKey: selectedSize.key,
						});
						pushToGTM({
							type: 'profiling_complete',
							payload: {
								productId,
							},
						});
					}
				}}
				onClose={() => {
					setIsPopoverOpen(false);
				}}
				onCloseDone={() => {
					reset();
					setIsFullyClosed(true);
				}}
				onPlacementClick={(placement) => {
					setSelectedPlacement(placement);
					setCurrentPageIndex((current) => current + 1);
					pushToGTM({
						type: 'profiling_placement',
						payload: {
							productId,
						},
					});
				}}
				onPrintUploadAbortClick={() => {
					setIsPopoverOpen(false);
				}}
				onRemovePrintClick={onRemovePrintClick}
				onSizeClick={(sizeOption) => {
					setSelectedSize(sizeOption);
					setCurrentPageIndex((current) => current + 1);
					pushToGTM({
						type: 'profiling_size',
						payload: {
							productId,
						},
					});
				}}
				onUpLoadPrint={(file) => {
					onUpLoadPrint(file);
					pushToGTM({
						type: 'profiling_upload',
						payload: {
							productId,
						},
					});
				}}
				placements={placements?.map((placement) => ({
					...placement,
					isSelected: Boolean(
						printPlacements?.some(
							(printPlacement) => printPlacement.id === placement.id,
						),
					),
				}))}
				sizes={selectedPlacement?.options}
				print={print}
				storedPrints={storedPrints}
				onReusePrintClick={onReusePrintClick}
				fileUploadButtonState={fileUploadButtonState}
				addPrintPlacementButtonState={addPrintPlacementButtonState}
				addNewPrintPlacementButtonText={reuseSubmitButton?.value}
				addPrintPlacementButtonText={uploadSubmitButton?.value}
				existingPrintPlacementsHeading={reuseHeading?.value}
				existingPrintPlacementsListHeading={reuseLabel?.value}
				existingPrintPlacementsDescription={reuseDescription?.value}
				placementDisabledText={placementDisabled?.value}
				placementHeading={placementHeading?.value}
				// Very unlikely to be undefined in practice
				popoverFallbackHeading={popoverName?.value || 'Print'}
				selectedPlacementPrice={selectedSize?.price}
				printUploadAbortButtonText={popoverCancel?.value}
				printUploadDescription={uploadDescription?.value}
				printUploadHeading={uploadHeading?.value}
				printUploadInfoText={uploadWarning?.value}
				printUploadPriceLabel={uploadPriceLabel?.value}
				removeUploadedPrintButtonText={uploadRemoveButton?.value}
				reusePrintButtonText={uploadReuseButton?.value}
				reuseUploadedPrintText={uploadReuseLabel?.value}
				selectExistingPrintPlacementButtonText={reusePrintButton?.value}
				sizeHeading={sizeHeading?.value}
				uploadButtonText={uploadPrintButton?.value}
				printUploadErrors={printUploadErrors}
				addPrintPlacementErrors={addPrintPlacementErrors}
			/>
		</div>
	);
}

ProductPrint.displayName = 'ProductPrint';

interface ProductPrintWrapperProps {
	addPrintPlacementButtonState: ActionButtonState;
	addPrintPlacementErrors: ValidationError | undefined;
	className?: string;
	existingPrintPlacements: ExistingPlacement[] | undefined;
	fileUploadButtonState: ActionButtonState;
	hasError: boolean;
	isLoading: boolean;
	onAddPrintPlacementClick: (newPrint: CreateCustomizationRequest) => void;
	onRemovePrintClick: (id: string) => void;
	onRemovePrintPlacementClick: (identifier: string) => void;
	onReusePrintClick: (id: string) => void;
	onSelectExistingPrintPlacementClick: (
		newPrint: CreateCustomizationRequest,
	) => void;
	onUpLoadPrint: (file: File) => void;
	placements: Placement[] | undefined;
	print: StoredFileResponse | undefined;
	printOnClothes: PrintOnClothes | undefined;
	printPlacements: CustomizationPlacement[] | undefined;
	printUploadErrors: string[] | undefined;
	productId: string;
	storedPrints: StoredFileResponse[] | undefined;
	total: string | undefined;
}

export default function ProductPrintWrapper({
	addPrintPlacementButtonState,
	addPrintPlacementErrors,
	className,
	existingPrintPlacements,
	fileUploadButtonState,
	hasError,
	isLoading,
	onAddPrintPlacementClick,
	onRemovePrintClick,
	onRemovePrintPlacementClick,
	onReusePrintClick,
	onSelectExistingPrintPlacementClick,
	onUpLoadPrint,
	placements,
	print,
	printOnClothes,
	printPlacements,
	printUploadErrors,
	productId,
	storedPrints,
	total,
}: ProductPrintWrapperProps) {
	if (isLoading) {
		return (
			<Skeleton className={className}>
				{/* Text, RichText, Button */}
				<SkeletonItem width="10rem" height="text" />
				<SkeletonItem height="text" className="mt-1" />
				<SkeletonItem width="10rem" height="1.5rem" className="mt-6" />
				<div className="h-1.5" />
			</Skeleton>
		);
	}
	if (hasError) {
		return null;
	}

	// if we don't have sitecore texts or placements there isn't much use
	// showing anything, checking for placements also deals with the possibility
	// that there might be a diff between the product saying it can be customised
	// but the api saying there isn't any customisation for it.
	if (!printOnClothes || !placements) {
		return null;
	}

	return (
		<ProductPrint
			className={className}
			onRemovePrintPlacementClick={onRemovePrintPlacementClick}
			printPlacements={printPlacements}
			onAddPrintPlacementClick={onAddPrintPlacementClick}
			onSelectExistingPrintPlacementClick={onSelectExistingPrintPlacementClick}
			print={print}
			placements={placements}
			onUpLoadPrint={onUpLoadPrint}
			existingPrintPlacements={existingPrintPlacements}
			total={total}
			storedPrints={storedPrints}
			addPrintPlacementButtonState={addPrintPlacementButtonState}
			fileUploadButtonState={fileUploadButtonState}
			onReusePrintClick={onReusePrintClick}
			printOnClothes={printOnClothes}
			onRemovePrintClick={onRemovePrintClick}
			printUploadErrors={printUploadErrors}
			addPrintPlacementErrors={addPrintPlacementErrors}
			productId={productId}
		/>
	);
}
ProductPrintWrapper.displayName = 'ProductPrintWrapper';
