import React from 'react';
import { useSelector } from '@xstate/react';

import WishlistBlock from 'components/Wishlist';
import { useGlobalMachinesContext } from 'contexts';
import { useShareWishlist } from 'hooks';
import { selectWishlist } from 'state-machines/wishlist';
import { useI18n } from 'utils/i18n';

export default function Wishlist() {
	const { wishlistService } = useGlobalMachinesContext();
	const { t } = useI18n();
	const wishlistData = useSelector(wishlistService, selectWishlist);
	const { copiedToClipboard, isSharing, shareWishlist } = useShareWishlist();

	return (
		<WishlistBlock
			heading={t('wishlist_wishlist_heading')}
			copiedToClipboard={copiedToClipboard}
			isSharing={isSharing}
			onShare={shareWishlist}
			wishlist={wishlistData}
			onEmptyWishlist={() => wishlistService.send({ type: 'REMOVE_ALL' })}
			moveVariantToCartButtonText={t('wishlist_move_product_to_cart_button')}
			moveAllVariantsToCartButtonText={t(
				'wishlist_move_all_products_to_cart_button',
			)}
			onRemoveVariant={(variant) => {
				if (!variant.id) return;
				wishlistService.send({
					type: 'REMOVE_VARIANT',
					variantId: variant.id,
				});
			}}
			onUpdateVariantQty={(variant, newQuantity) => {
				if (!variant.id) return;
				wishlistService.send({
					type: 'SET_VARIANT_QTY',
					variantId: variant.id,
					qty: newQuantity,
					GTMData:
						variant.qty - newQuantity < 0
							? {
									type: 'add_to_wishlist',
									payload: {
										product: variant,
										quantity: newQuantity - variant.qty,
									},
								}
							: undefined,
				});
			}}
		/>
	);
}
Wishlist.displayName = 'Wishlist';
