import React, { useEffect, useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSelector } from '@xstate/react';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Button from 'components/Button';
import GdprAccordion from 'components/GdprAccordion';
import GenericForm from 'components/GenericForm';
import {
	ErrorMessageInfoBox,
	useGlobalPopoverContext,
} from 'components/GlobalPopover';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useGlobalMachinesContext } from 'contexts';
import { useContactInformation, useCustomerInformation } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { StoredFileResponse } from 'models/api/userFileStorage';
import type { FileField, FormFields } from 'models/sitecore';
import {
	selectCustomerContactId,
	selectCustomerType,
	selectIsAuthenticatedUser,
} from 'state-machines/authentication';
import { selectIsClosing } from 'state-machines/global-popover.machine';
import { cn } from 'utils/classNames';
import fetchData, { API_URL } from 'utils/fetchData';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: {
		description: Field<string>;
		form: FormFields;
		heading: Field<string>;
		name: Field<string>;
		submitText: Field<string>;
	};
};

export default function ContactForm({ fields }: Props) {
	const popoverContext = useGlobalPopoverContext();
	const { globalPopoverService, userService } = useGlobalMachinesContext();
	const customerType = useSelector(userService, selectCustomerType);
	const customerContactId = useSelector(userService, selectCustomerContactId);
	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);

	const isClosing = useSelector(globalPopoverService, selectIsClosing);

	const {
		customerInformation,
		error: errorCustomerInformation,
		isLoading: isLoadingCustomerInformation,
	} = useCustomerInformation(isAuthenticatedUser);
	const { cellPhoneNumberFormatted, emailAddress, fullName } =
		customerInformation ?? {};

	const isPro = customerType === 'Pro';
	const {
		contactInformation,
		error: errorContactInformation,
		isLoading: isLoadingContactInformation,
	} = useContactInformation(customerContactId || '', isPro);

	let mobile = cellPhoneNumberFormatted?.e164;
	let email = emailAddress;

	if (isPro && contactInformation) {
		mobile = contactInformation.phoneNumber?.e164;
		email = contactInformation.email;
	}

	const isLoading =
		(isAuthenticatedUser && !isPro && isLoadingCustomerInformation) ||
		(isAuthenticatedUser && isPro && isLoadingContactInformation);

	const [submitButtonState, setSubmitButtonState] =
		useState<ActionButtonState>('idle');

	const [isSuccess, setIsSuccess] = useState(false);
	const { t } = useI18n();

	const onSubmit = async (genericFormData: Record<string, unknown>) => {
		popoverContext?.setTopMessage(undefined);
		const formData = { ...genericFormData };

		setSubmitButtonState('loading');

		const selectFields = form.filter((field) => field.type === 'SelectField');
		const fileField = form.find(
			(field): field is FileField => field.type === 'FileField',
		);

		selectFields.forEach((selectField) => {
			if (selectField.sendLabel) {
				const categoryId = formData[selectField.name];
				const selectedOption = selectField.options.find(
					(option) => option.value === categoryId,
				);
				formData[selectField.labelName] = selectedOption?.label;
			}
		});

		const attachments = (
			formData[`${fileField?.name}-data`] as StoredFileResponse[]
		)?.map((attachmentData) => attachmentData.fileName);

		if (attachments?.length > 0) {
			delete formData[`${fileField?.name}-data`];
			formData.attachments = attachments;
		}

		const response = await fetchData(`${API_URL}CustomerService/postForm`, {
			method: 'POST',
			body: JSON.stringify(formData),
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include',
		}).catch((error) => error);

		if (
			response?.fieldValidationErrors ||
			response?.businessLogicErrors ||
			response instanceof Error
		) {
			setSubmitButtonState('failure');
			if (is.arrayWithLength(response.businessLogicErrors)) {
				popoverContext?.setTopMessage(
					<ErrorMessageInfoBox errors={response.businessLogicErrors} />,
				);
			}
			if (is.objectWithKeys(response.fieldValidationErrors)) {
				return response.fieldValidationErrors;
			}
			return { FORM_ERROR: 'businessLogicErrors' };
		}
		setSubmitButtonState('success');
		setIsSuccess(true);
	};

	useEffect(() => {
		setIsSuccess(false);
		popoverContext?.setTopMessage(undefined);
	}, [isClosing]);

	if (!fields) {
		return null;
	}

	const { description, form, heading, submitText } = fields;
	const hasError = errorCustomerInformation || errorContactInformation;

	if (hasError) {
		return (
			<InfoBox
				icon="error"
				variant="error"
				message={t('general_critical_error_text')}
			/>
		);
	}

	return (
		<>
			<div className={cn(isSuccess && 'hidden')}>
				<Text className="mb-2" as="h2" field={heading} />
				<Text className="mb-2" as="p" field={description} />
				<GdprAccordion />
				{isLoading && (
					<Skeleton>
						<SkeletonItem height="5rem" className="mt-4" />
						<SkeletonItem height="10rem" className="mt-4" />
						<SkeletonItem height="20rem" className="mt-4" />
					</Skeleton>
				)}
				{!isLoading && (
					<>
						{isAuthenticatedUser && (
							<>
								<Text className="mb-4 font-bold" as="p">
									{t('contact_form_details_heading')}
								</Text>

								<InfoBox
									icon="info"
									variant="information"
									className="mb-4"
									message={t('contact_form_details_info_text')}
								/>
								<Text
									as="p"
									text={`${t(isPro ? 'contact_form_details_company_name_label' : 'contact_form_details_name_label')}: ${fullName}`}
								/>
								<Text
									as="p"
									text={`${t(
										'contact_form_details_customer_number_label',
									)}: ${customerInformation?.id}`}
								/>
								{isPro && (
									<Text
										as="p"
										text={`${t(
											'contact_form_details_contact_label',
										)}: ${contactInformation?.name}`}
									/>
								)}
							</>
						)}
						<GenericForm
							inputSpace="mt-4"
							fields={form}
							initialValues={{ email, mobile }}
							className="mt-4"
							fileUploadUrl={`${API_URL}UserFileStorage/ContactCustomerService`}
							onSubmitCallback={onSubmit}
							resetForm
							saveStateFor="ContactForm"
							button={
								<ActionButton
									className="mt-6"
									displayWidth="full"
									size="large"
									type="submit"
									variant="cta"
									customState={submitButtonState}
									minimunLoadingTime={0}
								>
									{submitText.value}
								</ActionButton>
							}
						/>
					</>
				)}
			</div>
			<div
				className={cn(
					'flex h-full flex-col content-between',
					!isSuccess && 'hidden',
				)}
			>
				<div>
					<Img
						width={80}
						height={80}
						src="/assets/images/graphic-signin-mail.svg"
						className="mx-auto mt-8 block size-20 object-cover"
					/>
					<Text as="h2" className="mt-8">
						{t('contact_form_thank_you_heading')}
					</Text>

					<Text as="p" className="mt-6">
						{t('contact_form_feedback_interval_text')}
					</Text>

					<div className="mt-8">
						<Button
							variant="primary"
							displayWidth="full"
							className="my-4"
							onClick={() => globalPopoverService.send('CLOSE')}
						>
							{t('contact_form_ok_button')}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}
ContactForm.displayName = 'ContactForm';
