/**
 * ImagePopover
 */

import React, { MouseEventHandler } from 'react';

import Icon from 'components/Icon';
import { useDialog } from 'hooks';
import { cn } from 'utils/classNames';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	children: React.ReactNode;
	/** Whether the layover is open or not */
	isOpen: boolean;
	/** dialog label */
	label: string;
	/** Function to call when the layover should be closed */
	onClose: () => void;
	onOpen: () => void;
}

/** A component that displays images in a popover
 * that is popover styled in mobile and windowed in desktop. */
export default function ImagePopover({
	children,
	isOpen,
	label,
	onClose,
	onOpen,
}: Props) {
	const { t } = useI18n();

	const { dialogProps, dialogRef, focusTrapEndProps, focusTrapStartProps } =
		useDialog({
			'aria-label': label,
			isOpen,
			onClose,
			onOpen,
		});

	const handleCloseButtonClick = () => {
		onClose();
	};

	// Close the popover if clicking outside of its content element.
	const handleBackgroundClick: MouseEventHandler<HTMLDivElement> = (e) => {
		// If offsetParent is null the ref element is hidden and should be ignored.
		if (
			isOpen &&
			dialogRef.current?.offsetParent &&
			is.node(e.target) &&
			!dialogRef.current.contains(e.target)
		) {
			onClose();
		}
	};

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div
			onClick={handleBackgroundClick}
			className={cn(
				'fixed inset-0 z-imageLayover flex items-end justify-end bg-black/20 outline-none md:items-center md:justify-center',
				'transition-fadeTransform duration-300 ease-out',
				!isOpen && 'pointer-events-none invisible opacity-0',
			)}
		>
			<div {...focusTrapStartProps} />

			<div
				{...dialogProps}
				className={cn(
					'relative flex h-5/6 w-full flex-col bg-white pt-5 md:h-full md:max-h-[calc(100%-4rem)] md:w-[calc(100%-4rem)] md:rounded-lg',
					'transition-transform duration-300 ease-out',
					!isOpen && 'translate-y-[10%]',
				)}
			>
				{children}

				<button
					type="button"
					onClick={handleCloseButtonClick}
					aria-label={t('product_details_close_image_layover_button')}
					className={cn(
						'group fixed flex items-center',
						'max-sm:bottom-6 max-sm:left-1/2 max-sm:-translate-x-1/2',
						'sm:absolute sm:right-6 sm:top-6',
					)}
				>
					<span className="mr-3 font-bold group-hover:underline max-sm:hidden">
						{t('product_details_close_image_layover_button')}
					</span>
					<span className="flex h-12 w-12 items-center justify-center rounded-full bg-julaRed group-hover:bg-julaRedDark">
						<Icon name="close" color="white" />
					</span>
				</button>
			</div>

			<div {...focusTrapEndProps} />
		</div>
	);
}
ImagePopover.displayName = 'ImageLayover';
